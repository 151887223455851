// ---------------------------------------------------------------------------------------------- //
// Chart
// ---------------------------------------------------------------------------------------------- //

// Metrics
export const GET_METRICS_SUCCESS = 'GET_METRICS_SUCCESS';
export const GET_METRICS_REQUEST = 'GET_METRICS_REQUEST';
export const GET_METRICS_FAILURE = 'GET_METRICS_FAILURE';

// Ranks
export const GET_RANKS_SUCCESS = 'GET_RANKS_SUCCESS';
export const GET_RANKS_REQUEST = 'GET_RANKS_REQUEST';
export const GET_RANKS_FAILURE = 'GET_RANKS_FAILURE';

export const SET_PREVIOUS_METRICS = 'SET_PREVIOUS_METRICS';
export const SET_NEXT_METRICS = 'SET_NEXT_METRICS';
