// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  CUSTOM_CLAIMS_REQUEST,
  CUSTOM_CLAIMS_SUCCESS,
  GET_PATIENT_REQUEST,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_FAILURE,
  FETCH_PATIENTS,
  UPDATE_PATIENTS,
  CUSTOM_CLAIMS_FAILURE, SET_PATIENT,
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Patient
} from "../../../utils/types";
import type {
  Action
} from "redux";

export type patientsReducerType = {
  list: Patient[],
  patient: Patient,
  pending: boolean,
  unsubscribe: any
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  patient: null,
  pending: false,
  unsubscribe: null,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: patientsReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case GET_PATIENT_REQUEST:
      newState.pending = true;
      return newState;
    case GET_PATIENT_SUCCESS:
      newState.patient = action.payload;
      newState.pending = false;
      return newState;
    case GET_PATIENT_FAILURE:
      newState.pending = false;

    case CUSTOM_CLAIMS_REQUEST:
      newState.pending = true;
      return newState;

    case CUSTOM_CLAIMS_SUCCESS:
    case CUSTOM_CLAIMS_FAILURE:
      newState.pending = false;
      return newState;

    case FETCH_PATIENTS:
      // Start spinning
      newState.pending = true;
      // Clear list
      newState.list = [];
      // Unsubscribe previous fetch if exist
      if (newState.unsubscribe) newState.unsubscribe();
      // Store new unSubscribe
      newState.unsubscribe = action.payload;
      return newState;

    case UPDATE_PATIENTS:
      // Stop spinning
      newState.pending = false;
      // Store new list
      newState.list = action.payload ? action.payload : [];
      return newState;

    case SET_PATIENT:
      newState.patient = action.payload;

    default:
      return newState;
  }
};
