// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// UI
import {
  FormControl,
  Button,
  withStyles,
  CardContent,
  TextField,
  Card,
  CardHeader,
  CardActions,
} from '@material-ui/core';

// Type
import { ThunkAction } from 'redux-thunk';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// UI
import Spinner from '../../../ui/Spinner';
import SnackbarError from '../ui/SnackbarError';


// Icons
import {
  SignUpIcon
} from "../../../styles/icons";

// Utils
import {
  PASSWORD_REGEX,
} from '../../../../shared/utils/constants';

// Fonctions
import {
  SignUp
} from '../redux/actions';

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  classes: {},
  SignUp: ThunkAction,
  isLogged: boolean,
  pending: boolean,
};

type State = {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
};

// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:  'center',
  },
  card: {
    padding: theme.spacing(1) ,
    maxWidth: 600,
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardContent: {
    paddingTop: 0,
  },
  cardActions: {
    width: 600,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonLink: {
    marginBottom: '0.35em',
  },
});

// ---------------------------------------------------------------------------------------------- //
// Component
// ---------------------------------------------------------------------------------------------- //

class Signup extends React.Component<Props, State> {

  // -------------------------------------------------------------------------------------------- //
  // Life cycle
  // -------------------------------------------------------------------------------------------- //

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    }
  };

  // -------------------------------------------------------------------------------------------- //
  // Functions
  // -------------------------------------------------------------------------------------------- //

  onSignup() {
    // Grab state
    const { email, password, firstName, lastName } = this.state;
    // Call action
    this.props.SignUp(email, password, firstName, lastName);
  }

  onChange(event) {
    this.setState({[event.target.id]: event.target.value})
  }

  checkPassword() {
    return PASSWORD_REGEX.test(this.state.password);
  }

  // -------------------------------------------------------------------------------------------- //
  // Render
  // -------------------------------------------------------------------------------------------- //

  render() {
    // Grab props and states
    const { firstName, lastName, email, password} = this.state;
    const { classes, isLogged, pending } = this.props;

    // Return login otherwise
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title='Inscription'
          />
          <CardContent className={classes.cardContent}>
            <FormControl margin='normal' fullWidth>
              <TextField
                id="firstName"
                label="Prénom"
                className={classes.textField}
                type="text"
                margin="normal"
                onChange={(event) => this.onChange(event)}
              />
              <TextField
                id="lastName"
                label="Nom de famille"
                className={classes.textField}
                type="text"
                margin="normal"
                onChange={(event) => this.onChange(event)}
              />
              <TextField
                id="email"
                label="Email"
                className={classes.textField}
                type="email"
                margin="normal"
                onChange={(event) => this.onChange(event)}
              />
              <TextField
                id="password"
                label="Choisissez un mot de passe"
                className={classes.textField}
                type="password"
                margin="normal"
                onChange={(event) => this.onChange(event)}
                onKeyPress={ (e) => {
                  if (e.key === 'Enter') {
                    onSignup();
                  }
                }}
              />
            </FormControl>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                variant='contained'
                color="primary"
                disabled={!firstName || !lastName || !email || !this.checkPassword() || pending}
                onClick={() => this.onSignup()}
              >
                <SignUpIcon className={classes.leftIcon}/>
                S'inscrire
              </Button>
              <Spinner
                spinning={pending}
              />
            </CardActions>
            <Spinner
              spinning={pending}
            />
        </Card>
        <SnackbarError/>
      </div>
    )
  }
}

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

const mapStateToProps = ({ auth }) => {
  const { isLogged, pending } = auth;
  return {
    isLogged,
    pending,
  };
};

const mapDispatchToProps = {
  SignUp,
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Signup);
