// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

// ---------------------------------------------------------------------------------------------- //
// Import
// ---------------------------------------------------------------------------------------------- //

import App from "./App";
import store from "./store"
import theme from './shared/styles/theme';

// Style
import './shared/styles/index.css';

// ---------------------------------------------------------------------------------------------- //
// Render
// ---------------------------------------------------------------------------------------------- //

ReactDOM.render(
  <Provider store={store}>
    <Router basename="/">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
