// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

import {parse} from "date-fns";
import * as gs1js from 'gs1js';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import type {
  Barcode
} from "./types";

// ---------------------------------------------------------------------------------------------- //
// Functions
// ---------------------------------------------------------------------------------------------- //

export const parseDataMatrix = (dataMatrix: string, type): Barcode => {
  const reader = new gs1js.GS1Reader(dataMatrix);
  // Read the found AIs
  const AIs = reader.getApplicationIdentifiers();
  // Init result
  const result: Barcode = {
    barcode: dataMatrix,
    type,
  };
  // Loop through identifier
  for (const AI of AIs) {
    if (AI.identifier === '01') result.product = AI.value;
    if (AI.identifier === '17') {
      // Check that day is set and not equal to 00, otherwise set it to 01
      if (AI.value.substring(4, 6) === '00') AI.value = `${AI.value.substring(0, 4)}01`;
      // Parse date string
      const expiry = parse(AI.value, 'yyMMdd', new Date());
      if (expiry && !isNaN(expiry.getTime())) result.expiry = expiry;
    }
    if (AI.identifier === '10') result.lot = AI.value;
    if (AI.identifier === '21') result.serial = AI.value;
  }
  return result;
};

export const parseEAN13 = (ean13: string, type): Barcode => {
  const product = ean13.length === 13 ? '0' + ean13 : ean13;
  return { product, barcode: ean13, type };
};

export const parseBarCode = (barcode: string, type: string): Barcode => {
  switch (type) {
    case 'org.iso.DataMatrix':
    case 'DATA_MATRIX':
      return parseDataMatrix(barcode, type);
    case 'org.gs1.EAN-13':
    case 'org.iso.Code128':
    case 'org.iso.Code39':
      return parseEAN13(barcode, type);
    default:
      return null;
  }
};
