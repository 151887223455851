import { createMuiTheme } from '@material-ui/core/styles';

import {
  primary,
  secondary
} from "./colors";

const theme = createMuiTheme({
   palette: {
     primary: {
       main: primary,
     },
     secondary: {
       main: secondary,
     },
   },
});

export default theme;
