// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  CUSTOM_CLAIMS_REQUEST,
  CUSTOM_CLAIMS_SUCCESS,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEES,
  UPDATE_EMPLOYEES,
  CUSTOM_CLAIMS_FAILURE,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAILURE, CLEAR_ERROR, DELETE_EMPLOYEE_REQUEST, DELETE_EMPLOYEE_FAILURE, DELETE_EMPLOYEE_SUCCESS
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Employee,
  Patient
} from "../../../utils/types";
import type {
  Action
} from "redux";

export type employeesReducerType = {
  list: Employee[],
  employee: Employee,
  pending: boolean,
  unsubscribe: any,
  error: any,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  employee: null,
  pending: false,
  unsubscribe: null,
  error: null,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: employeesReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case GET_EMPLOYEE_REQUEST:
    case CREATE_EMPLOYEE_REQUEST:
      newState.employee = null;
      newState.pending = true;
      newState.error = null;
      return newState;
    case DELETE_EMPLOYEE_REQUEST:
      newState.pending = true;
      newState.error = null;
      return newState;
    case GET_EMPLOYEE_SUCCESS:
    case CREATE_EMPLOYEE_SUCCESS:
      newState.employee = action.payload;
      newState.pending = false;
      return newState;
    case DELETE_EMPLOYEE_SUCCESS:
      newState.employee = null;
      newState.pending = false;
      return newState;
    case GET_EMPLOYEE_FAILURE:
    case CREATE_EMPLOYEE_FAILURE:
    case DELETE_EMPLOYEE_FAILURE:
      newState.pending = false;
      newState.error = action.payload;
      return newState;
    case CUSTOM_CLAIMS_REQUEST:
      newState.pending = true;
      return newState;

    case CUSTOM_CLAIMS_SUCCESS:
    case CUSTOM_CLAIMS_FAILURE:
      newState.pending = false;
      return newState;

    case FETCH_EMPLOYEES:
      // Start spinning
      newState.pending = true;
      // Clear list
      newState.list = [];
      // Unsubscribe previous fetch if exist
      if (newState.unsubscribe) newState.unsubscribe();
      // Store new unSubscribe
      newState.unsubscribe = action.payload;
      return newState;

    case UPDATE_EMPLOYEES:
      // Stop spinning
      newState.pending = false;
      // Store new list
      newState.list = action.payload ? action.payload : [];
      return newState;

    case CLEAR_ERROR:
      newState.error = null;
      return newState;

    default:
      return newState;
  }
};
