// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Types
import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_FAILURE,
  IS_LOGGED_FAILURE,
  IS_LOGGED_REQUEST,
  IS_LOGGED_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SEND_PASSWORD_RESET_MAIL_REQUEST,
  SEND_PASSWORD_RESET_MAIL_SUCCESS,
  SEND_PASSWORD_RESET_MAIL_FAILURE,
  FAILURE_RESET,
  ANONYMOUS_LOGIN_REQUEST,
  ANONYMOUS_LOGIN_SUCCESS,
  ANONYMOUS_LOGIN_FAILURE,
  PATIENT_SIGNUP_REQUEST,
  PATIENT_SIGNUP_SUCCESS,
  PATIENT_SIGNUP_FAILURE,
  PHONE_VERIFICATION_REQUEST,
  PHONE_VERIFICATION_SUCCESS,
  PHONE_VERIFICATION_FAILURE,
  PHONE_VERIFICATION_CANCEL,
  CLEAR_SUCCESS,
  PATIENT_LOGIN_REQUEST,
  PATIENT_LOGIN_SUCCESS,
  PATIENT_LOGIN_FAILURE,
  UPDATE_CLAIMS_REQUEST,
  UPDATE_CLAIMS_SUCCESS, UPDATE_CLAIMS_FAILURE,
} from "./action-types";
import {SET_ERROR} from "../../errors/redux/action-types";

// ---------------------------------------------------------------------------------------------- //
// Actions
// ---------------------------------------------------------------------------------------------- //

export const SignUp = (
  firebase: any,
  api: any,
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phone: string,
  country: string
) => async dispatch => {
  try {
    dispatch({ type: SIGNUP_REQUEST });
    const firebaseUser = await firebase.signup(email, password);
    // Call API
    const fetch = [
      await firebase.getClaims(firebaseUser, true),
      await firebase.getDeviceId(),
      await signupUser(api, firstName, lastName, phone, country),
    ];
    const claims = fetch[0];
    const deviceId = fetch[1];
    const user = fetch[2];

    dispatch({ type: SIGNUP_SUCCESS, payload: { firebaseUser, claims, deviceId, user } });
  } catch (error) {
    dispatch({ type: SIGNUP_FAILURE, payload: error });

  }
};

export const LogIn = (
  firebase,
  api,
  email,
  password
) => async dispatch => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    await firebase.login(email, password);
    const firebaseUser = await firebase.getFirebaseUser();
    // Get claims and deviceId in //
    const fetch = [
      await firebase.getClaims(firebaseUser, true),
      await firebase.getDeviceId(),
      await getUser(api, firebaseUser.uid)
    ];
    const claims = fetch[0];
    const deviceId = fetch[1];
    const user = fetch[2];
    // Store in redux
    dispatch({ type: LOGIN_SUCCESS, payload: { claims, deviceId, firebaseUser, user } });
  } catch (error) {
    console.error(error);
    dispatch({ type: LOGIN_FAILURE, payload: error });
  }
};

export const LogInAnonymously = (firebase) => async dispatch => {
  try {
    dispatch({ type: ANONYMOUS_LOGIN_REQUEST });
    await firebase.loginAnonymously();
    // Get user and deviceId in //
    const fetch = [
      await firebase.getFirebaseUser(),
      await firebase.getDeviceId()
    ];
    const firebaseUser = fetch[0];
    const deviceId = fetch[1];
    // Store in redux
    dispatch({ type: ANONYMOUS_LOGIN_SUCCESS, payload: { firebaseUser, deviceId } });
  } catch (error) {
    dispatch({ type: ANONYMOUS_LOGIN_FAILURE, payload: error });
  }
};

export const VerifyPhoneCancel = () => async dispatch => dispatch({ type: PHONE_VERIFICATION_CANCEL });

export const LogInPatient = (
  firebase: any,
  api: any,
  phone: string,
  country: string,
  confirmResult?: any,
  verificationCode?: number,
) => async dispatch => {
  // Verify phone number if no verification code is provided
  if (!verificationCode || !confirmResult) return verifyPhoneNumber(dispatch, firebase, phone, country);
  // Verify code otherwise
  try {
    dispatch({ type: PATIENT_LOGIN_REQUEST });
    const firebaseUser = await firebase.signupPhoneNumber(confirmResult.verificationId, verificationCode);
    const fetch = [
      await firebase.getClaims(firebaseUser, true),
      await firebase.getDeviceId(),
      await updateUser(api, firebaseUser.uid, null, null, phone, country)
    ];
    const claims = fetch[0];
    const deviceId = fetch[1];
    const user = fetch[2];
    dispatch({ type: PATIENT_LOGIN_SUCCESS, payload: { firebaseUser, claims, deviceId, user } });
  } catch (error) {
    console.warn('PATIENT_LOGIN_FAILURE', error);
    // Stop spinning
    dispatch({ type: PATIENT_LOGIN_FAILURE });
    // Display error
    dispatch({ type: SET_ERROR, payload: error });
  }
};

export const SignUpPatient = (
  firebase: any,
  api: any,
  phone: string,
  country: string,
  confirmResult?: any,
  verificationCode?: number,
) => async dispatch => {
  // Verify phone number if no verification code is provided
  if (!verificationCode || !confirmResult) return verifyPhoneNumber(dispatch, firebase, phone, country);
  // Verify code otherwise
  try {
    dispatch({ type: PATIENT_SIGNUP_REQUEST });
    const firebaseUser = await firebase.linkPhoneNumber(confirmResult.verificationId, verificationCode);
    // If ok, no existing phone account but link to existing one, call API to update phone and country
    const fetch = [
      await firebase.getClaims(firebaseUser, true),
      await firebase.getDeviceId(),
      await updateUser(api, firebaseUser.uid, null, null, phone, country)
    ];
    const claims = fetch[0];
    const deviceId = fetch[1];
    const user = fetch[2];
    dispatch({ type: PATIENT_SIGNUP_SUCCESS, payload: { firebaseUser, claims, deviceId, user } });
  } catch (error) {
    console.warn('PATIENT_SIGNUP_FAILURE', error);
    // Stop spinning
    dispatch({ type: PATIENT_SIGNUP_FAILURE });
    // Display error
    dispatch({ type: SET_ERROR, payload: error });
  }
};

const verifyPhoneNumber = async (
  dispatch: any,
  firebase: any,
  phone: string,
  country: string,
) => {
  try {
    dispatch({ type: PHONE_VERIFICATION_REQUEST, payload: { phone, country } });
    // First verify phone number
    const confirmResult = await firebase.verifyPhoneNumber(phone);
    dispatch({ type: PHONE_VERIFICATION_SUCCESS, payload: confirmResult });
  } catch (error) {
    console.warn(error);
    dispatch({ type: PHONE_VERIFICATION_FAILURE, payload: error });
  }
}

export const LogOut = (firebase) => async dispatch => {
  try {
    dispatch({ type: LOGOUT_REQUEST });
    await firebase.logout();
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAILURE });
  }
};

export const IsLogged = (firebase) => async dispatch => {
  try {
    dispatch({ type: IS_LOGGED_REQUEST });
    // Get user
    const firebaseUser = await firebase.getFirebaseUser();
    // Get claims and device Id in //
    const fetch = [
      await firebase.getClaims(firebaseUser, true),
      await firebase.getDeviceId(),
    ];
    // Store in redux
    const claims = fetch[0];
    const deviceId = fetch[1];
    dispatch({ type: IS_LOGGED_SUCCESS, payload: { firebaseUser, claims, deviceId } });
  } catch (error) {
    dispatch({ type: IS_LOGGED_FAILURE, payload: error });
  }
};

export const UpdateClaims = (firebase) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CLAIMS_REQUEST });
    // Get user
    const claims = await firebase.getClaims(null, true);
    dispatch({ type: UPDATE_CLAIMS_SUCCESS, payload: claims });
  } catch (error) {
    dispatch({ type: UPDATE_CLAIMS_FAILURE, payload: error });
  }
};


export const SendPasswordResetEmail = (firebase, email) => async dispatch => {
  try {
    dispatch({ type: SEND_PASSWORD_RESET_MAIL_REQUEST });
    await firebase.sendPasswordResetEmail(email);
    dispatch({ type: SEND_PASSWORD_RESET_MAIL_SUCCESS });
  } catch (error) {
    dispatch({ type: SEND_PASSWORD_RESET_MAIL_FAILURE, payload: error });
  }
};

export const CloseFailure = () => dispatch => {
  dispatch({ type: FAILURE_RESET });
};

export const ClearSuccess = () => dispatch => {
  dispatch({ type: CLEAR_SUCCESS });
};

// ---------------------------------------------------------------------------------------------- //
// API
// ---------------------------------------------------------------------------------------------- //

const signupUser = async (
  api: any,
  firstName?: string,
  lastName?: string,
  phone?: string,
  country?: string,
): Promise<any> => await api.api(
  `v1/users`,
  "post",
  null,
  {
    firstName,
    lastName,
    phone,
    country
  },
  false
);

const updateUser = async (
  api: any,
  userId: string,
  firstName?: string,
  lastName?: string,
  phone?: string,
  country?: string,
): Promise<any> => await api.api(
  `v1/users/${userId}`,
  "put",
  null,
  {
    firstName,
    lastName,
    phone,
    country
  },
  false
);

const getUser = async (
  api: any,
  userId: string,
): Promise<any> => await api.api(
  `v1/users/${userId}`,
  "get",
  null,
  {},
  false
);
