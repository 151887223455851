// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions
import {
  IsLogged,
  LogInAnonymously,
  LogOut,
} from "../shared-web/modules/auth/redux/actions";

// Routes
import * as Routes from '../shared/routes';

// UI
import NavBar from "../components/Navbar";
import ScanCamera from '../shared-web/components/ScanCamera';
import ScanResult from '../shared-web/modules/scans/components/ScanResult';
import ScanDocument from '../shared-web/modules/scans/components/ScanDocument';

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  IsLogged: ThunkAction,
  LogInAnonymously: ThunkAction,
  classes: {},
};


// ---------------------------------------------------------------------------------------------- //
// Style
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flexStart',
  },
  content: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'scroll',
  }
});

// ---------------------------------------------------------------------------------------------- //
// Component
// ---------------------------------------------------------------------------------------------- //

class Dashboard extends React.Component<Props, State> {

  // -------------------------------------------------------------------------------------------- //
  // Style
  // -------------------------------------------------------------------------------------------- //

  // -------------------------------------------------------------------------------------------- //
  // Life Cycle
  // -------------------------------------------------------------------------------------------- //

  componentDidMount() {
    console.log("ici on log")
    //this.props.IsLogged();
//    const test = LogInAnonymously()
    //this.props.LogOut()
    //console.log("on ce log: ", test())
  }
  // -------------------------------------------------------------------------------------------- //
  // Render
  // -------------------------------------------------------------------------------------------- //

  // Render
  render() {
    // Grab props
    const { classes, pending, isLogged, fetched, } = this.props;
    if (pending || (!isLogged && fetched)) {
      //Anonymous login if not logged
      if (!isLogged && fetched) {
        this.props.LogInAnonymously();
      }
      return (
        <p>pending</p>
        );
    }
    return (
      <div className={classes.root}>
        <NavBar profile={false}/>
        {isLogged && 
        <div className={classes.content}>
          <Switch>
            <Route path={Routes.scanner} render={() => <ScanCamera />} />
            <Route path={Routes.scan} render={() => <ScanDocument/>} />
            <Redirect to={Routes.scanner} />
          </Switch>
        </div>
        }
      </div>
    );
  }
}

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

const mapStateToProps = ({ auth }) => {
  const { isLogged, fetched, pending } = auth;
  return {
    isLogged,
    fetched,
    pending,
  };
};

const mapDispatchToProps = {
  LogInAnonymously,
  IsLogged,
  LogOut,
};


// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Dashboard);
