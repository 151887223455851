// @flow

// ---------------------------------------------------------------------------------------------- //
// Auth
// ---------------------------------------------------------------------------------------------- //

export const login = '/connexion';
export const signup = '/inscription';
export const reset = '/reinitialisation';
export const onboard = '/bienvenu';
export const walkthrough = '/walkthrough';

// ---------------------------------------------------------------------------------------------- //
// Enroll
// ---------------------------------------------------------------------------------------------- //

export const enroll = '/rejoindre';
export const enrollSuccess = `${enroll}/success`;

// ---------------------------------------------------------------------------------------------- //
// Dashboard
// ---------------------------------------------------------------------------------------------- //

export const home = '/';

export const medications = '/specialites';
export const medication = `${medications}/:medicationId`;

export const products = '/medicaments';
export const product = `${products}/:productId`;

export const leaflets = '/notices';
export const leaflet = `${leaflets}/:productId`;

export const lots = '/lots';
export const lot = `${lots}/:lotId`;

export const items = '/boites';
export const item = `${items}/:itemId`;
export const itemScans = `${item}/scans`;

export const scans = '/scans';
export const scan = `${scans}/:scanId`;
export const scanCamera = '/scancamera';
export const pendingScans = `${scans}/attentes`;
export const acceptedScans = `${scans}/acceptes`;
export const refusedScans = `${scans}/refuses`;

export const warehouses = '/depots';
export const warehouse = `${warehouses}/:depotId`;

export const factories = '/usines';
export const factory = `${factories}/:factoryId`;

export const distributors = '/distributeurs';
export const distributor = `${distributors}/:distributorId`;

export const customs = '/douanes';
export const custom = `${customs}/:customId`;

export const regulators = '/regulateur';
export const regulator = `${regulators}/:regulatorId`;

export const pharmacies = '/pharmacies';
export const pharmacy = `${pharmacies}/:pharmacyId`;
export const pharmacyBySlug = `${pharmacies}/slug/:pharmacySlug`;

export const employees = '/employes';
export const employee = `${employees}/:employeeId`;
export const addEmployee = `${employee}/ajouter`;

export const patients = '/patients';
export const patient = `${patients}/:patientId`;

export const suppliers = '/fournisseurs';
export const supplier = `${suppliers}/:supplierId`;

export const rewards = '/recompenses';

export const reward = `${rewards}/:requestId`;
export const rewardRequest = `${rewards}/obtenir`;
export const rewardSuccess = `${rewards}/succes`;

export const accesses = '/acces';
export const access = `${accesses}/:accessId`;

export const profile = '/profile';
export const profileEditor = `${profile}/modifier`;

export const contact = '/contact';

export const myPharmacy = '/pharmacie';

export const scanner = '/scanner';

export const map = '/carte';

export const settings = '/parametres';

export const questions = '/questions';
export const dialogs = '/dialogs';

export const question = `${dialogs}/:dialogId/questions/:questionId`;

export const search = '/rechercher';
export const searchPharmacies = `${search}/pharmacies`;

export const requests = '/requests';
export const request = `${requests}/:requestId`;
export const pendingRequests = `${requests}/pending`;
export const acceptedRequests = `${requests}/accepted`;
export const refusedRequests = `${requests}/refused`;

export const notifications = '/notifications';

export const posts = '/actualites';
export const post = `${posts}/:postId`;

export const users = '/utilisateurs';
export const addUser = `${users}/ajouter`;
export const userScanner = `${users}/scanner`;
export const userScan = `${users}${scan}`;

export const gaming = `/fidelisation`;
export const uploadItems = '/upload/items';
