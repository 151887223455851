// @flow

export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const UPDATE_EMPLOYEES = 'UPDATE_EMPLOYEES';

export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';

export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';

export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';

export const CUSTOM_CLAIMS_REQUEST = 'CUSTOM_CLAIMS_REQUEST';
export const CUSTOM_CLAIMS_SUCCESS = 'CUSTOM_CLAIMS_SUCCESS';
export const CUSTOM_CLAIMS_FAILURE = 'CUSTOM_CLAIMS_FAILURE';

export const CLEAR_ERROR = 'CLEAR_ERROR';