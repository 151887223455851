export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const ANONYMOUS_LOGIN_REQUEST = 'ANONYMOUS_LOGIN_REQUEST';
export const ANONYMOUS_LOGIN_SUCCESS = 'ANONYMOUS_LOGIN_SUCCESS';
export const ANONYMOUS_LOGIN_FAILURE = 'ANONYMOUS_LOGIN_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const PATIENT_SIGNUP_REQUEST = 'PATIENT_SIGNUP_REQUEST';
export const PATIENT_SIGNUP_SUCCESS = 'PATIENT_SIGNUP_SUCCESS';
export const PATIENT_SIGNUP_FAILURE = 'PATIENT_SIGNUP_FAILURE';

export const PATIENT_LOGIN_REQUEST = 'PATIENT_LOGIN_REQUEST';
export const PATIENT_LOGIN_SUCCESS = 'PATIENT_LOGIN_SUCCESS';
export const PATIENT_LOGIN_FAILURE = 'PATIENT_LOGIN_FAILURE';

export const PHONE_VERIFICATION_REQUEST = 'PHONE_VERIFICATION_REQUEST';
export const PHONE_VERIFICATION_SUCCESS = 'PHONE_VERIFICATION_SUCCESS';
export const PHONE_VERIFICATION_FAILURE = 'PHONE_VERIFICATION_FAILURE';
export const PHONE_VERIFICATION_CANCEL = 'PHONE_VERIFICATION_CANCEL';

export const IS_LOGGED_REQUEST = 'IS_LOGGED_REQUEST';
export const IS_LOGGED_SUCCESS = 'IS_LOGGED_SUCCESS';
export const IS_LOGGED_FAILURE = 'IS_LOGGED_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SEND_PASSWORD_RESET_MAIL_REQUEST = 'SEND_PASSWORD_RESET_MAIL_REQUEST';
export const SEND_PASSWORD_RESET_MAIL_SUCCESS = 'SEND_PASSWORD_RESET_MAIL_SUCCESS';
export const SEND_PASSWORD_RESET_MAIL_FAILURE = 'SEND_PASSWORD_RESET_MAIL_FAILURE';

export const FAILURE_RESET = 'FAILURE_RESET';

export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';

export const UPDATE_CLAIMS_REQUEST = 'UPDATE_CLAIMS_REQUEST';
export const UPDATE_CLAIMS_SUCCESS = 'UPDATE_CLAIMS_SUCCESS';
export const UPDATE_CLAIMS_FAILURE = 'UPDATE_CLAIMS_FAILURE';
