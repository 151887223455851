// ---------------------------------------------------------------------------------------------- //
// Fetch/Update Rewards
// ---------------------------------------------------------------------------------------------- //

export const FETCH_REWARDS = 'FETCH_REWARDS';
export const UPDATE_REWARDS = 'UPDATE_REWARDS';

// ---------------------------------------------------------------------------------------------- //
// Claims
// ---------------------------------------------------------------------------------------------- //

export const CUSTOM_CLAIMS_REQUEST = 'CUSTOM_CLAIMS_REQUEST';
export const CUSTOM_CLAIMS_SUCCESS = 'CUSTOM_CLAIMS_SUCCESS';
export const CUSTOM_CLAIMS_FAILURE = 'CUSTOM_CLAIMS_FAILURE';

// ---------------------------------------------------------------------------------------------- //
// Get Rewards
// ---------------------------------------------------------------------------------------------- //

export const GET_REWARD_REQUEST = 'GET_REWARD_REQUEST';
export const GET_REWARD_SUCCESS = 'GET_REWARD_SUCCESS';
export const GET_REWARD_FAILURE = 'GET_REWARD_FAILURE';

// ---------------------------------------------------------------------------------------------- //
// POST Rewards
// ---------------------------------------------------------------------------------------------- //

export const POST_REWARD_REQUEST = 'POST_REWARD_REQUEST';
export const POST_REWARD_SUCCESS = 'POST_REWARD_SUCCESS';
export const POST_REWARD_FAILURE = 'POST_REWARD_FAILURE';

// ---------------------------------------------------------------------------------------------- //
// Get Suffix
// ---------------------------------------------------------------------------------------------- //

export const GET_VOUCHER_REQUEST = 'GET_VOUCHER_REQUEST';
export const GET_VOUCHER_SUCCESS = 'GET_VOUCHER_SUCCESS';
export const GET_VOUCHER_FAILURE = 'GET_VOUCHER_FAILURE';
export const REMOVE_VOUCHER_SUCCESS = 'REMOVE_VOUCHER_SUCCESS';

