export const PUT_USER_REQUEST = 'PUT_USER_REQUEST';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_FAILURE = 'PUT_USER_FAILURE';

export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';

export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
