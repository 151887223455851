// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

import axios from 'axios';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

const endpoint = axios.create();

// ---------------------------------------------------------------------------------------------- //
// API
// ---------------------------------------------------------------------------------------------- //

// Cancellation token
export const cancelToken = () => axios.CancelToken.source();

// API
export const api = async (
  firebase: any,
  baseURL: string,
  url: string,
  method: string,
  cancellationToken: any,
  params: any = {},
  unauth: boolean,
) => {
  try {
    // Get current user from firebase
    const firebaseUser = await firebase.getFirebaseUser();
    // Get token and userId
    const token = await firebase.getToken(firebaseUser, false);
    const claims = await firebase.getClaims(firebaseUser, false);
    const userId = firebaseUser.uid;
    if (!token) throw new Error('Unauthenticated');
    // Start trace API
    const metric = await firebase.startTraceAPI(method, `${baseURL}/${url}`, userId, claims);
    // Create config based on parameters
    const config = {
      baseURL,
      method,
      url,
      params,
      cancelToken: cancellationToken ? cancellationToken.token : null,
      headers: token ? { Authorization: `Bearer ${token}` } : null,
    };
    // console.warn(config);
    // Send api request
    const response = await endpoint.request(config);
    // Stop trace API
    await firebase.stopTraceAPI(metric, response);
    // Throw an error if response is not Ok
    if (!response || response.status !== 200) throw new Error(response.statusText);
    // Return response data
    return response.data;
  } catch (error) {
    //console.error(error);
    throw error;
  }
};

export const download = async (url: string) => {
  const response = await axios({
    url,
    method: 'GET',
    responseType: 'blob', // important
  });
  return await readFile(response.data);
};

const readFile = (data: any) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject("Problem parsing input file.");
    };
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsText(data);
  });
};
