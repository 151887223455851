// @flow

export const POST_DIALOG_REQUEST = 'POST_DIALOG_REQUEST';
export const POST_DIALOG_SUCCESS = 'POST_DIALOG_SUCCESS';
export const POST_DIALOG_FAILURE = 'POST_DIALOG_FAILURE';

export const PUT_QUESTION_REQUEST = 'PUT_QUESTION_REQUEST';
export const PUT_QUESTION_SUCCESS = 'PUT_QUESTION_SUCCESS';
export const PUT_QUESTION_FAILURE = 'PUT_QUESTION_FAILURE';

export const GET_QUESTION_REQUEST = 'GET_QUESTION_REQUEST';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';

export const SET_PHARMACY_ID = 'SET_PHARMACY_ID';
export const CLEAR_PHARMACY_ID = 'CLEAR_PHARMACY_ID';

export const SET_PHARMACY_NAME = 'SET_PHARMACY_NAME';
export const CLEAR_PHARMACY_NAME = 'CLEAR_PHARMACY_NAME';
