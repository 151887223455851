// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  CUSTOM_CLAIMS_REQUEST,
  CUSTOM_CLAIMS_SUCCESS,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  FETCH_PRODUCTS,
  UPDATE_PRODUCTS,
  CUSTOM_CLAIMS_FAILURE,
  UPDATE_SCANS, GET_WAREHOUSE_REQUEST, GET_WAREHOUSE_SUCCESS, GET_WAREHOUSE_FAILURE
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Warehouse
} from "../../../utils/types";
import type {
  Action
} from "redux";

export type warehousesReducerType = {
  list: Warehouse[],
  warehouse: Warehouse,
  pending: boolean,
  unsubscribe: any,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  warehouse: null,
  pending: false,
  unsubscribe: null,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: warehousesReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case GET_WAREHOUSE_REQUEST:
      newState.pending = true;
      return newState;
    case GET_WAREHOUSE_SUCCESS:
      newState.warehouse = action.payload;
      newState.pending = false;
      return newState;
    case GET_WAREHOUSE_FAILURE:
      newState.pending = false;


    default:
      return newState;
  }
};
