// ---------------------------------------------------------------------------------------------- //
// Fetch/Update Suppliers
// ---------------------------------------------------------------------------------------------- //

export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const UPDATE_SUPPLIERS = 'UPDATE_SUPPLIERS';

// ---------------------------------------------------------------------------------------------- //
// Claims
// ---------------------------------------------------------------------------------------------- //

export const CUSTOM_CLAIMS_REQUEST = 'CUSTOM_CLAIMS_REQUEST';
export const CUSTOM_CLAIMS_SUCCESS = 'CUSTOM_CLAIMS_SUCCESS';
export const CUSTOM_CLAIMS_FAILURE = 'CUSTOM_CLAIMS_FAILURE';

// ---------------------------------------------------------------------------------------------- //
// Get Suppliers
// ---------------------------------------------------------------------------------------------- //

export const GET_SUPPLIER_REQUEST = 'GET_SUPPLIER_REQUEST';
export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';
export const GET_SUPPLIER_FAILURE = 'GET_SUPPLIER_FAILURE';
