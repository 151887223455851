// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  GET_REWARD_REQUEST,
  GET_REWARD_SUCCESS,
  GET_REWARD_FAILURE,
  CUSTOM_CLAIMS_REQUEST,
  CUSTOM_CLAIMS_SUCCESS,
  CUSTOM_CLAIMS_FAILURE,
  FETCH_REWARDS,
  UPDATE_REWARDS,
  GET_VOUCHER_REQUEST,
  GET_VOUCHER_SUCCESS,
  GET_VOUCHER_FAILURE,
  REMOVE_VOUCHER_SUCCESS, POST_REWARD_REQUEST, POST_REWARD_SUCCESS, POST_REWARD_FAILURE,
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Reward
} from "../../../utils/types";
import type {
  Action
} from "redux";

export type rewardsReducerType = {
  list: Reward[],
  reward: Reward,
  pending: boolean,
  voucher: string,
  unsubscribe: any,
  error: any,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  reward: null,
  pending: false,
  voucher: null,
  unsubscribe: null,
  error: null,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: rewardsReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case GET_REWARD_REQUEST:
    case CUSTOM_CLAIMS_REQUEST:
    case GET_VOUCHER_REQUEST:
    case POST_REWARD_REQUEST:
      newState.pending = true;
      newState.reward = null;
      return newState;

    case GET_REWARD_SUCCESS:
    case POST_REWARD_SUCCESS:
      newState.reward = action.payload;
      newState.pending = false;
      return newState;

    case GET_VOUCHER_SUCCESS:
      newState.voucher = action.payload;
      newState.pending = false;
      return newState;

    case GET_VOUCHER_FAILURE:
      newState.voucher = null;
      newState.pending = false;
      return newState;

    case GET_REWARD_FAILURE:
    case CUSTOM_CLAIMS_SUCCESS:
    case CUSTOM_CLAIMS_FAILURE:
    case POST_REWARD_FAILURE:
      newState.error = action.payload;
      newState.pending = false;
      return newState;

    case FETCH_REWARDS:
      // Start spinning
      newState.pending = true;
      // Clear list
      newState.list = [];
      // Unsubscribe previous fetch if exist
      if (newState.unsubscribe) newState.unsubscribe();
      // Store new unSubscribe
      newState.unsubscribe = action.payload;
      return newState;

    case UPDATE_REWARDS:
      // Stop spinning
      newState.pending = false;
      // Store new list
      newState.list = action.payload ? action.payload : [];
      return newState;

    case REMOVE_VOUCHER_SUCCESS:
      newState.voucher = null;
      return newState;

    default:
      return newState;
  }
};
