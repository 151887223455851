// ---------------------------------------------------------------------------------------------- //
// Clusters
// ---------------------------------------------------------------------------------------------- //

export const GET_SCANS_SUCCESS = 'GET_SCANS_SUCCESS';
export const GET_SCANS_REQUEST = 'GET_SCANS_REQUEST';
export const GET_SCANS_FAILURE = 'GET_SCANS_FAILURE';

export const FETCH_SCANS = 'FETCH_SCANS';
export const UPDATE_SCANS = 'UPDATE_SCANS';
export const FETCH_SCANS_FAILURE = 'FETCH_SCANS_FAILURE';

// ---------------------------------------------------------------------------------------------- //
// Scan
// ---------------------------------------------------------------------------------------------- //

export const GET_SCAN_SUCCESS = 'GET_SCAN_SUCCESS';
export const GET_SCAN_REQUEST = 'GET_SCAN_REQUEST';
export const GET_SCAN_FAILURE = 'GET_SCAN_FAILURE';

export const POST_SCAN_REQUEST = 'POST_SCAN_REQUEST';
export const POST_SCAN_SUCCESS = 'POST_SCAN_SUCCESS';
export const POST_SCAN_FAILURE = 'POST_SCAN_FAILURE';

export const CLEAR_SCAN = 'CLEAR_SCAN';

export const CLEAR_SCAN_ERROR = 'CLEAR_SCAN_ERROR';

export const RESET_COUNTER = 'RESET_COUNTER';

export const SHOULD_BEEP = 'SHOULD_BEEP';

export const ADD_TO_SERIALS = 'ADD_TO_SERIALS';
