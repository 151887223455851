// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
    Action
} from "redux";

import type {
    Notification
} from "../../../utils/types";

export type notificationsReducerType = {
    list: Notification[],
    pending: boolean,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
    list: [],
    pending: false,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
    state: notificationsReducerType = initialState,
    action: Action,
) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case GET_NOTIFICATION_REQUEST:
            newState.pending = true;
            return newState;
        case GET_NOTIFICATION_SUCCESS:
            newState.pending = true;
            newState.list = action.payload ? action.payload : [];
            return newState;
        case GET_NOTIFICATION_FAILURE:
            newState.pending = true;
            newState.list = [];
            return newState;
        default:
            return newState;
    }
};
