// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/performance";

const Timestamp = firebase.firestore.Timestamp;

// ---------------------------------------------------------------------------------------------- //
// Import
// ---------------------------------------------------------------------------------------------- //

import * as firebaseCore from '../../shared/utils/firebase';

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //




// ---------------------------------------------------------------------------------------------- //
// Config
// ---------------------------------------------------------------------------------------------- //

// Config dotenv
require('dotenv').config();

firebase.initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
});

const perf = firebase.performance();

// ---------------------------------------------------------------------------------------------- //
// References
// ---------------------------------------------------------------------------------------------- //

export const firestore = (): firebase.firestore.Firestore => firebase.firestore();

export const collectionReference = (
  collection: string
): firebase.firestore.CollectionReference => firestore().collection(collection);

export const collectionGroup = (
  collection: string
): firebase.firestore.CollectionReference => firestore().collectionGroup(collection);

// ---------------------------------------------------------------------------------------------- //
// Fetch
// ---------------------------------------------------------------------------------------------- //

export const fetchDocument = async (
  documentReference: firebase.firestore.DocumentReference,
  update: Object => void
): any => firebaseCore.fetchDocument(documentReference, update);

export const fetchCollection = (
  collectionReference: firebase.firestore.CollectionReference,
  update: Object[] => void
): () => void => firebaseCore.fetchCollection(collectionReference, update);

// ---------------------------------------------------------------------------------------------- //
// Auth
// ---------------------------------------------------------------------------------------------- //

export const login = async (email: string, password: string) => firebaseCore.login(firebase, email, password);

export const logout = async () => firebaseCore.logout(firebase);

export const signup = async (email: string, password: string) => firebaseCore.signup(firebase, email, password);

export const getFirebaseUser = async (): Promise<firebase.User> => firebaseCore.getFirebaseUser(firebase);

export const getFirebaseUserId = async (): Promise<string> => firebaseCore.getFirebaseUser(firebase);

export const getIdTokenResult = async(user: firebase.User, forceRefresh: boolean) => firebaseCore.getIdTokenResult(firebase, user, forceRefresh);

export const getClaims = async (user: firebase.User, forceRefresh: boolean) => firebaseCore.getClaims(firebase, user, forceRefresh);

export const getToken = async (forceRefresh: boolean) => firebaseCore.getToken(firebase, forceRefresh);

export const sendPasswordResetEmail = async (email: string) => firebaseCore.sendPasswordResetEmail(firebase, email);

export const loginAnonymously = async () => firebaseCore.loginAnonymously(firebase);

// ---------------------------------------------------------------------------------------------- //
// Instance ID
// ---------------------------------------------------------------------------------------------- //

export const getDeviceId = async () => firebaseCore.getDeviceId(firebase);

// ---------------------------------------------------------------------------------------------- //
// Perfomance
// ---------------------------------------------------------------------------------------------- //

export const startTraceAPI = async (method: string, url: string, userId: string) => {};

export const stopTraceAPI = async (metric: any, response: any) => {};

// ---------------------------------------------------------------------------------------------- //
// Timestamp
// ---------------------------------------------------------------------------------------------- //

export const convertDate = (object) => firebaseCore.convertDate(Timestamp, object)

export const convertDateArray = (objects) => firebaseCore.convertDateArray(Timestamp, objects);
