// ---------------------------------------------------------------------------------------------- //
// Scans
// ---------------------------------------------------------------------------------------------- //

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';

// ---------------------------------------------------------------------------------------------- //
// Scan
// ---------------------------------------------------------------------------------------------- //

export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

export const CUSTOM_CLAIMS_REQUEST = 'CUSTOM_CLAIMS_REQUEST';
export const CUSTOM_CLAIMS_SUCCESS = 'CUSTOM_CLAIMS_SUCCESS';
export const CUSTOM_CLAIMS_FAILURE = 'CUSTOM_CLAIMS_FAILURE';


export const UPDATE_SCANS = 'UPDATE_SCANS';
