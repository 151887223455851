// ---------------------------------------------------------------------------------------------- //
// const
// ---------------------------------------------------------------------------------------------- //

export const QuestionType = {
  purchase_channel: 'purchase_channel',
  which_pharmacy: 'which_pharmacy',
  pharmacy_confirmation: 'pharmacy_confirmation',
  is_relative: 'is_relative'
};

export const AnswerType = {
  boolean: 'boolean',
  string: 'string',
  choice: 'choice',
  search: 'search'
};

export const PurchaseChannel = {
  pharmacy: 'pharmacy',
  street: 'street',
  hospital: 'hospital',
  unknown: 'unknown',
};

export const Gender = {
  female: 'female',
  male: 'male'
};

export const Language = {
  fr: 'fr',
};

export const WarningReason = {
  UNLEGIT_CHANNEL: 'UNLEGIT_CHANNEL',
  MULTIPLE_PHARMACIES: 'MULTIPLE_PHARMACIES',
  MULTIPLE_USERS: 'MULTIPLE_USERS'
};

export const CodeLevel = {
  anatomical: 'anatomical',
  therapeutic: 'therapeutic',
  pharmacological: 'pharmacological',
  chemical: 'chemical',
  substance: 'substance',
};

export const MessageType = {
  success: 'success',
  danger: 'danger',
  warning: 'warning',
};

export const MessagePosition = {
  top: 'top',
  bottom: 'bottom',
};

export const MessageDuration = {
  normal: 3600,
};

export const ScanType = {
  user: 'user',
  pharmacy: 'pharmacy',
  company: 'company',
  regulator: 'regulator',
  onboard: 'onboard',
};

export const RequestType = {
  pharmacy_enroll: 'pharmacy_enroll',
  reward: 'reward',
};

export const RequestStatus = {
  pending: 'pending',
  accepted: 'accepted',
  refused: 'refused',
};

export const Program = {
  two_hundred_mega: 'two_hundred_mega',
  five_hundred_mega: 'five_hundred_mega',
  one_giga: 'one_giga',
};

export const EventType = {
  item : 'item',
  patient: 'patient',
  favorite : 'favorite',
};

export const BarcodeFormat = [
  '', // AZTEC
  '', // CODABAR
  'code39', // CODE_39
  '', // CODE_93
  'code128', // CODE_128
  'datamatrix', // DATA_MATRIX
  '', // EAN_8
  'gs1EAN13', // EAN_13
  '', // ITF
  '', // MAXICODE
  '', // PDF_417
  '', // QR_CODE
  '', // RSS_14
  '', // RSS_EXPANDED
  '', // UPC_A
  '', // UPC_E
  '', // UPC_EAN_EXTENSION
]

export const BarcodeType = {
  datamatrix: 'org.iso.DataMatrix',
  gs1EAN13: 'org.gs1.EAN-13',
  code128: 'org.iso.Code128',
  code39: 'org.iso.Code39',
}

export const Errors = {
  already_scanned: "Boîte déjà scannée",
};

