// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import {format} from "date-fns";

// Type
import { ThunkAction } from 'redux-thunk';

// UI
import {
  withStyles,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { timestampDateFormatter } from "../../../../shared/utils/formaters";

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import {
  status,
  color
} from "../../../../shared/modules/scans/model";


// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  classes: {},
  scan: {},
  GetScan: ThunkAction,
};

// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  card: {
      width: '600px',
      margin: '15px',
  },

});

// ---------------------------------------------------------------------------------------------- //
// Component
// ---------------------------------------------------------------------------------------------- //

class ScanDetail extends React.Component<Props, State> {

  // -------------------------------------------------------------------------------------------- //
  // Function
  // -------------------------------------------------------------------------------------------- //

  // -------------------------------------------------------------------------------------------- //
  // Life Cycle
  // -------------------------------------------------------------------------------------------- //

  constructor(props) {
    super(props);
  }

  render() {
    const { scan } = this.props;

    // Return null if empty
    if (!scan) return null;

    // Grab values otherwise
    const {
      id,
      createdAt,
      item,
      product,
      lot
    } = scan;

    return (
      <TableRow
        key={id}
        hover
      >
        <TableCell><i style={{ fontSize: '.8em' }}>{timestampDateFormatter(createdAt)}</i></TableCell>
        <TableCell>{product ? product.name : ''}</TableCell>
        <TableCell>{lot ? lot.lot : ''}</TableCell>
        <TableCell>{item ? item.item : ''}</TableCell>
        <TableCell>
          <i style={{ color: color(scan) }}>{status(scan)}</i>
        </TableCell>
      </TableRow>
    );
  }
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default withStyles(styles)(ScanDetail);
