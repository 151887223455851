// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import {
    withStyles,
  } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';

// Type
import { ThunkAction } from 'redux-thunk';


// Enums

import { 
    BarcodeType,
    BarcodeFormat
} from '../../shared/utils/enums'

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Action 

import {
    PostScan,
} from '../modules/scans/redux/actions'
// UI


// Type

// Routes

import * as Routes from '../../shared/routes';
import ScanResult from '../modules/scans/components/ScanResult';
import { mergeClasses } from '@material-ui/styles';
import Spinner from '../ui/Spinner';

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //


type Props = {
    PostScan: ThunkAction,
};

type State = {
};

// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'scroll',
  },
  scanner: {
      width: '100vw'
    }
});

// ---------------------------------------------------------------------------------------------- //
// Components
// ---------------------------------------------------------------------------------------------- //

class ScanCamera extends React.Component<Props, State> {

    // -------------------------------------------------------------------------------------------- //
    // Constructor
    // -------------------------------------------------------------------------------------------- //

    constructor(props) {
        super(props);
        this.state = {
            result: null,
            error: '',
            lat: 7.485529,
            lng: -5.425385,
            scan: null,
            // setup array for match with BarcodeType enum and format of result output scan
        }
    }

    // -------------------------------------------------------------------------------------------- //
    // Life Cycle
    // -------------------------------------------------------------------------------------------- //
    
    componentDidMount(): void {
        this.scaner()
        //this.videoRef.addEventListener('onPlaying', this.scaner)
            /* this.props.PostScan({
                barcode: this.resutl.text
            }) */
            /* this.props.PostScan(
                '01035855540889802110867604225331722083110X3978',
                BarcodeType.datamatrix,
                '',
                this.state.lat,
                this.state.lng
            ) */
    }


    // -------------------------------------------------------------------------------------------- //
    // function
    // -------------------------------------------------------------------------------------------- //

    scaner = () => {
        const codeReader = new BrowserMultiFormatReader();

        codeReader.decodeFromVideoDevice(undefined, this.videoRef, (result, error) => {
            //console.log("ccodereader = ", codeReader)
            if (result && !this.props.scan) {
                const type = BarcodeType[BarcodeFormat[result.format]]
                if (type) {
                    /// stop listener
                    codeReader.stopStreams();
                    /// post scan 
                    this.props.PostScan(
                        result.text, // barcode
                        type,
                        '', // device empty in web app
                        this.state.lat,
                        this.state.lng
                        )
                    /* this.setState({
                        getScanSucces:
                    }) */
                }

            } else if (error && !(error instanceof NotFoundException)) {
                console.log("error = ", error)
                this.setState({
                    error: error
                })
            }
        })
    }
        // -------------------------------------------------------------------------------------------- //
        // Render
        // -------------------------------------------------------------------------------------------- //
        
    render() {
        console.log("props scanCamera = ", this.props)
        const { classes, scan, pending } = this.props
        if (scan) {
            return (
                <Redirect to={`${Routes.scans}/${scan.id}`} /> 
            )
            // this.props.history.push(`${Routes.scans}/${this.props.scan.id}`)  
        } 
        //console.log("ici")
        // if (this.props.scan) {
        //     console.log("redirect, scan = ", this.props.scan)
        //     return (
        //         <ScanResult scan={this.props.scan}/>
        //     )
        // }
        console.log("pending = ", pending)
        return (
            <div>
                {!pending ? 
                <video
                    id="video"
                    ref={(ref) => this.videoRef = ref}
                    className={classes.scanner}
                ></video>
                : 
                <p> Loading ... </p>}
            </div>
        );
    }
}

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

const mapStateToProps = ({ scans }) => {
    return {
        scan: scans.scan,
        pending: scans.pending,
    };
};

const mapDispatchToProps = {
    PostScan,
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(ScanCamera);
