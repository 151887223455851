// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import { compose } from 'redux';
import { withRouter } from "react-router";

// UI

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Colors
import {
  primary,
  color,
  orange,
  text,
  grey,
  white,
} from "../../../../shared/styles/colors";

// image

import googleBadge from "../../../../shared/static/img/other/google-play-badge.png"
import appleBadge from "../../../../shared/static/img/other/appelBadge.png"

// // Model
import {
  status
} from "../../../../shared/modules/scans/model";

// // Styles

import {
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';

// // UI
import {
  Container,
  Card,
  CardContent,
  ListItem,
  TextField,
  ListItemText,
  ListItemIcon,
  Typography,
  Button,
} from '@material-ui/core';
import {
  InformationIcon,
} from "../../../styles/icons";

// Routes
import { timestampDateFormatter } from "../../../../shared/utils/formaters";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  scan: any,
};

// ---------------------------------------------------------------------------------------------- //
// Style
// ---------------------------------------------------------------------------------------------- //

// const styles = StyleSheet.create({
//   root: {
//     display: 'flex',
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor: primary,
//     padding: 16,
//   },
//   element: {
//     marginTop: 16,
//     marginBottom: 16,
//   },
//   horizontal: {
//     flexDirection: 'row',
//     alignItems: 'center',
//   },
//   horizontalFull: {
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   },
//   name: {
//     textAlign: 'center',
//     color: text,
//     marginTop: 24,
//   },
//   status: {
//     textAlign: 'center',
//     color: 'white',
//   },
//   card: {
//     alignContent: 'center',
//     marginTop: 24,
//     marginBottom: 0,
//     padding: 24
//   },
//   warning: {
//     backgroundColor: orange,
//   },
//   details: {
//     marginTop: 10,
//   },
//   detail: {
//     color: '#727272'
//   },
//   view: {
//     flex: 1,
//     margin: 16
//   },
//   expiry: {
//     textAlign: 'center',
//     color: grey
//   }
// });
const ButtonPetition = withStyles({
  root: {
    width: '100%',
    fontSize: '4vw',
    textTransform: 'capitalize',
    padding: '20px',
    backgroundColor: primary,
    color: white,
  }
})(Button);

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  nameProduct: {
    fontSize: '4vw',
  },
  expiry: {
    fontSize: '3vw',
  },
  restrictionList: {
    height: "7vh",
  },
  restrictionText: {
    fontSize: '3vw'
  },
  items: {
    fontSize: '3vw',
  },
  card: {
    height: '20vw',
  },
  contentCard: {
    fontSize: "7vw",
    textAlign: 'center',
  },
  badges: {
    '& img': {
      width: "50%",
    }
  },
  appleBadge: {
  }
}));

// ---------------------------------------------------------------------------------------------- //
// Component
// ---------------------------------------------------------------------------------------------- //

const ScanResult = ({ scan }: Props) => {
  // Spin if pending

  const classes = useStyles()

  console.log("props scanResult = ", scan)
  if (!scan) return null;
  const content = {
    restriction: "Adultes et enfants de plus de 15 ans et plus de 50 Kg.",
    dosages: [{
      icon: <InformationIcon />,
      text: "Comprimé à dissoudre entièrement dans un verre d'eau."
    }, {
      icon: <InformationIcon />,
      text: "1 Comprimé par prise."
    }, {
      icon: <InformationIcon />,
      text: "2 Comprimés MAXIMUM si douleurs ou fièvres intense."
    }, {
      icon: <InformationIcon />,
      text: "À renouveler toutes les 4 HEURES si besoin."
    }, {
      icon: <InformationIcon />,
      text: "MAXIMUM 6 comprimés par jour."
    }]
  }
  // Render scan otherwise
  return (
    <Container className={classes.container}>
     {scan.product && scan.product.name &&
        <h2 className={classes.nameProduct}>{scan.product.name}</h2>
      }

      {scan.item && scan.item.expiry &&
        <Typography className={classes.expiry} >{`Expire le ${timestampDateFormatter(scan.item.expiry)}`}</Typography>
      }

      {scan.legit ?
        <Card className={classes.card} style={{backgroundColor: color(scan)}}
        >
          {/*TODO: add meditect logo*/}
          <h3 className={classes.contentCard} >{status(scan).toUpperCase()}</h3>

        </Card>
        :
        <Card className={classes.card} style={{backgroundColor: color(scan)}}
        >
          {/*TODO: add meditect logo*/}
          <h1 className={classes.contentCard}>{status(scan)}</h1>

        </Card>
      }
      <div
      >

        {scan.product && scan.product.leaflet &&
          <div>
            <ListItem className={classes.restrictionList}>
              <ListItemText>
                <p className={classes.restrictionText}>{content.restriction}</p>
              </ListItemText>
            </ListItem>
            {content.dosages.map((dosage, index) => <ListItem className={classes.items} key={index}>
              <ListItemIcon>
                {dosage.icon}
              </ListItemIcon>
              <div>
                <ListItemText >
                  <p className={classes.items}>{dosage.text}</p>
                </ListItemText>
              </div>
            </ListItem>)}
          </div>}
        {!scan.item &&
          <Card	>
            <CardContent >
              <div>
                <TextField >
                  Nous ne sommes malheureusement pas en mesure de garantir l’authenticité de ce médicament.
              </TextField>
              </div>
            </CardContent>
            <CardContent >
              <div>
                <TextField>
                  Si vous souhaitez authentifier ce médicament avec Meditect, nous vous invitons à signer cette pétition.
                  Grâce à vos signatures, nous pourrons travailler avec plus de laboratoires, vous permettant ainsi de pouvoir authentifier plus de médicaments.
              </TextField>
              </div>
            </CardContent>
          </Card>
        }
      </div>
      <div className={classes.badges}>
        <img
          className={classes.appleBadge}
          alt="badge apple"
          src={appleBadge}
        />
        <img
          alt="badge google"
          src={googleBadge}
        />
      </div>
      <div>
        <ButtonPetition>
          signer la pétition
        </ButtonPetition>
      </div>
    </Container>

  );
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  withRouter,
)(ScanResult);
