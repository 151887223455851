// @flow

// ---------------------------------------------------------------------------------------------- //
// Lib
// ---------------------------------------------------------------------------------------------- //

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// ---------------------------------------------------------------------------------------------- //
// Import
// ---------------------------------------------------------------------------------------------- //

import reducers from './reducers';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  storeEnhancers(applyMiddleware(thunk))
);

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default store;
