// @flow

// ---------------------------------------------------------------------------------------------- //
// Import
// ---------------------------------------------------------------------------------------------- //

import {green, grey, orange, red} from "../../styles/colors";
import {capitalize, timestampDateFormatter} from "../../utils/formaters";

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export const status = ({legit, warning, fraud, item}) => {
  // Switch according to flags
  if (fraud) {
    return "Danger identifié";
  } else if (warning) {
    return "Risque identifié";
  } else if (legit) {
    return "Authentique"
  } else if (!item) {
    return "Inconnu";
  } else {
    return "Médicament inconnu";
  }
};

export const info = (scan) => {
  // Grab scan info
  const {item, lot} = scan;
  // Add info if exist
  const list = [];
  if (lot) list.push(`lot ${lot.number}`);
  if (item) list.push(`numéro ${item.serial}`);
  if (item && item.expiry) list.push(`expire ${timestampDateFormatter(item.expiry)}`);
  // Join then and caps
  return capitalize(list.join(', '));
};
