// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

import {
  format,
  parse,
  differenceInDays,
  formatDistanceToNow,
  formatDistanceStrict
} from 'date-fns';

import {
  parsePhoneNumberFromString,
  AsYouType
} from 'libphonenumber-js'


import { fr } from 'date-fns/locale'
import * as camelCase from 'camelcase';

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// ---------------------------------------------------------------------------------------------- //
// Types
// ---------------------------------------------------------------------------------------------- //

import {
  OpeningHours,
  OpenStatus
} from './types';

import { Gender, Program, EventType } from './enums'

// ---------------------------------------------------------------------------------------------- //
// Style
// ---------------------------------------------------------------------------------------------- //

import {
  orange,
  red,
  green
} from '../styles/colors'

// ---------------------------------------------------------------------------------------------- //
// Date
// ---------------------------------------------------------------------------------------------- //

export const dateFormatter = (date: Date) => format(date, 'dd/MM/yyyy');

export const hourFormatter = (date: Date) => format(date, 'HH:mm');

export const dateAndHourFormatter = (date: Date) => `${dateFormatter(date)}, ${hourFormatter(date)}`;

export const timestampParser = (timestamp: number): Date => timestamp ? parse(timestamp, 'T', new Date()) : null;

export const timestampDateFormatter = (timestamp: number) => timestampParser(timestamp)
  && dateFormatter(timestampParser(timestamp))
  || '';

export const timestampHourFormatter = (timestamp: number) => hourFormatter(timestampParser(timestamp));

export const timestampDateAndHourFormatter = (timestamp: number) => dateAndHourFormatter(timestampParser(timestamp));

export const dayFormatter = (date: any, invalidLabel: string) => {
  if (!date) return invalidLabel;
  switch (differenceInDays(new Date(), date)) {
    case 0:
      return "Aujourd'hui";
    case 1:
      return "Hier";
    case 2:
      return "Avant-hier";
    default:
      return format(date, 'eeee d LLLL YYY', { locale: fr });
  }
};

export const durationFromTimestamp = (timestamp) => timestampParser(timestamp) ? formatDistanceToNow(timestampParser(timestamp), { locale: fr }) : '';

// ---------------------------------------------------------------------------------------------- //
// String
// ---------------------------------------------------------------------------------------------- //

export const nameFormatter = (user: { firstName: string, lastName: string }) => {
  if (!user) return null;
  if (!user.firstName || !user.lastName) return "Nom inconnu";
  return `${user.firstName} ${user.lastName}`;
};

export const capitalize = (text: string) => text ? text.charAt(0).toUpperCase() + text.slice(1) : null;

// ---------------------------------------------------------------------------------------------- //
// Phone
// ---------------------------------------------------------------------------------------------- //

export const phoneAsYouTypeFormatter = (phone: string, country: string) => new AsYouType(country).input(phone);

export const phoneInternationalFormatter = (phone: string, country: string) => {
  // Stop if empty
  if (!phone) return null;
  // Try to parse phone number
  const phoneNumber = parsePhoneNumberFromString(phone, country);
  // Stop if not valid
  if (!phoneNumber || !phoneNumber.isValid()) return null;
  // Format it in national otherwise
  return phoneNumber.number;
};

export const phoneNationalFormatter = (phone: string, country: string): string => {
  // Stop if empty
  if (!phone) return null;
  // Try to parse phone number
  const phoneNumber = parsePhoneNumberFromString(phone, country);
  // Stop if not valid
  if (!phoneNumber || !phoneNumber.isValid()) return null;
  // Format it in national otherwise
  return phoneNumber.formatNational();
};

// ---------------------------------------------------------------------------------------------- //
// Gender
// ---------------------------------------------------------------------------------------------- //

export const genderToCivilities = (gender: string): string => {
  switch (gender) {
    case Gender.male:
      return "M."
    case Gender.female:
      return "Mme"
  }
};

export const genderFormatter = (gender: string): string => {
  switch (gender) {
    case Gender.male:
      return "Homme";
    case Gender.female:
      return "Femme";
  }
};

// ---------------------------------------------------------------------------------------------- //
// hours
// ---------------------------------------------------------------------------------------------- //

export const openStatus = (openingHours: OpeningHours): OpenStatus => {

  if (!openingHours)
    return {};
  if ((!openingHours.openAt || !openingHours.closeAt) && openingHours.openNow) {
    return { status: 'Ouverte', color: green };
  } else if ((!openingHours.openAt || !openingHours.closeAt) && !openingHours.openNow) {
    return {}
  }

  const now = new Date();
  const open = new Date(openingHours.openAt);
  const close = new Date(openingHours.closeAt);
  const delta = 30;
  const willOpen = formatDistanceStrict(now, open, { unit: 'minute' }).split(' ')[0];
  const willClose = formatDistanceStrict(now, close, { unit: 'minute' }).split(' ')[0];

  if (willOpen < willClose && willOpen <= delta) {
    return willOpen > 1
      ? { status: `Ouvre dans ${willOpen} mins`, color: orange }
      : { status: `Ouvre dans 1 minLala`, color: orange }
  } else if (willClose < willOpen && willClose <= delta) {
    return willClose > 1
      ? { status: `Ferme dans ${willClose} mins`, color: orange }
      : { status: `Ferme dans 1 min`, color: orange }
  } else if (openingHours.openNow) {
    return { status: `Ouverte`, color: green }
  } else if (openingHours.openNow === false) {
    return { status: `Fermée`, color: red }
  }
  return {}
};

export const formaterDistance = (distance: number): string => {
  return distance >= 1000
    ? `à ${Math.round(distance / 100) / 10} km`
    : `à ${Math.round(distance / 10) * 10} m`
};

export const convertCSVtoJSON = (contentBuffer: number) => {
  // initialize
  const lines = contentBuffer.split("\n"),
    headers = lines[0].split(",").map(hearder => camelCase(hearder)),
    firstLine = lines[1].split(","),
    obj = {
      'manufacturingDate': firstLine[0],
      'tradeItemDescription': firstLine[1],
      'gs1GlobalTradeItemNumber': firstLine[2],
      'expiryDate': firstLine[3],
      'lotNumber': firstLine[4],
      'serialNumbers': [],
    };
  // loop on all items
  for (let i = 1; i < lines.length - 1; i++) {
    // get current line
    let currentLine = lines[i].split(",");
    // add serial number in object
    obj['serialNumbers'].push(currentLine[5]);
  }
  // return
  return obj;
};

// ---------------------------------------------------------------------------------------------- //
// Program
// ---------------------------------------------------------------------------------------------- //

export const programFormatter = (program: string): string => {
  switch (program) {
    case Program.two_hundred_mega:
      return "Gagnez 200 Mo d'internet";
    case Program.five_hundred_mega:
      return "Gagnez 500 Mo d'internet";
    case Program.one_giga:
      return "Gagnez 1 Go d'internet";
  }
};

// ---------------------------------------------------------------------------------------------- //
// Events type
// ---------------------------------------------------------------------------------------------- //

export const eventTypeFormatter = (eventType: string): string => {
  switch (eventType) {
    case EventType.item:
      return 'Scan d\'une boîte de médicament'
    case EventType.patient:
      return 'Scan d\'une boîte par un patient';
    case EventType.favorite:
      return 'Pharmacie réferente d\'un patient';
  }
};
