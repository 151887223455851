// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  GET_SCANS_REQUEST,
  GET_SCANS_SUCCESS,
  GET_SCANS_FAILURE,
  FETCH_SCANS,
  UPDATE_SCANS,
  GET_SCAN_REQUEST,
  GET_SCAN_SUCCESS,
  GET_SCAN_FAILURE,
  POST_SCAN_REQUEST,
  POST_SCAN_SUCCESS,
  POST_SCAN_FAILURE,
  CLEAR_SCAN,
  FETCH_SCANS_FAILURE,
  RESET_COUNTER,
  SHOULD_BEEP, CLEAR_SCAN_ERROR, ADD_TO_SERIALS,
} from "./action-types";

import {
  POST_DIALOG_REQUEST,
  POST_DIALOG_SUCCESS,
  PUT_QUESTION_REQUEST,
  PUT_QUESTION_SUCCESS
} from "../../dialogs/redux/action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Scan,
  Item,
  Lot,
  Product,
} from "../../../utils/types";
import type {
  Action
} from "redux";

export type scansReducerType = {
  list: Scan[],
  items: Item[],
  lots: Lot[],
  products: Product[],
  scan: Scan,
  fetched: boolean,
  pending: boolean,
  fetch: boolean,
  unsubscribe: any,
  address: any,
  shouldBeep: boolean,
  serials: string[],
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  scan: null,
  fetched: false,
  fetch: false,
  pending: false,
  unsubscribe: null,
  address:null,
  shouldBeep: false,
  serials: [],
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: pharmacyReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case GET_SCANS_REQUEST:
      newState.pending = true;
      return newState;
    case GET_SCANS_SUCCESS:
      newState.list = action.payload;
      newState.pending = false;
      return newState;
    case GET_SCANS_FAILURE:
      newState.pending = false;
      return newState;
    case GET_SCAN_REQUEST:
    case POST_SCAN_REQUEST:
      newState.fetched = false;
      newState.scan = null;
      newState.pending = true;
      return newState;
    case POST_DIALOG_SUCCESS:
    case PUT_QUESTION_SUCCESS:
      newState.scan = action.payload.scan;
      newState.fetched = true;
      return newState;

    case POST_DIALOG_REQUEST:
    case PUT_QUESTION_REQUEST:
      newState.scan = null;
      newState.fetched = false;
      return newState;

    case GET_SCAN_SUCCESS:
      newState.scan = action.payload;
      newState.pending = false;
      newState.fetched = true;
      return newState;
    case GET_SCAN_FAILURE:
    case POST_SCAN_FAILURE:
    case FETCH_SCANS_FAILURE:
      newState.pending = false;
      newState.error = action.payload;
      return newState;

    case POST_SCAN_SUCCESS:
      newState.pending = false;
      newState.fetched = true;
      newState.scan = action.payload;
      return newState;

    case FETCH_SCANS:
      newState.pending = false;
      // Start spinning
      newState.fetch = true;
      // Clear list
      newState.list = [];
      // Unsubscribe previous fetch if exist
      if (newState.unsubscribe) newState.unsubscribe();
      // Store new unSubscribe
      newState.unsubscribe = action.payload;
      return newState;

    case UPDATE_SCANS:
      newState.pending = false;
      // Stop spinning
      newState.fetch = false;
      // Store new list
      newState.list = action.payload ? action.payload.scans : [];
      newState.items = action.payload ? action.payload.items : [];
      newState.lots = action.payload ? action.payload.lots : [];
      newState.products = action.payload ? action.payload.products : [];
      return newState;

    case CLEAR_SCAN:
      newState.scan = null;
      return newState;

    case CLEAR_SCAN_ERROR:
      newState.error = null;
      return newState;

    case RESET_COUNTER:
      newState.serials = [];
      return newState;

    case SHOULD_BEEP:
      newState.shouldBeep = action.payload;
      return newState;

    case ADD_TO_SERIALS:
      if (action.payload) newState.serials.push(action.payload);
      return newState;

    default:
      return newState;
  }
};
