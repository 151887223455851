// ---------------------------------------------------------------------------------------------- //
// Requests
// ---------------------------------------------------------------------------------------------- //

export const POST_REQUEST_SUCCESS = 'POST_REQUEST_SUCCESS';
export const POST_REQUEST_REQUEST = 'POST_REQUEST_REQUEST';
export const POST_REQUEST_FAILURE = 'POST_REQUEST_FAILURE';

// ---------------------------------------------------------------------------------------------- //
// Get
// ---------------------------------------------------------------------------------------------- //

export const GET_PHARMACY_REQUEST = 'GET_PHARMACY_REQUEST';
export const GET_PHARMACY_SUCCESS = 'GET_PHARMACY_SUCCESS';
export const GET_PHARMACY_FAILURE = 'GET_PHARMACY_FAILURE';

export const GET_GEO_PHARMACIES_REQUEST = 'GET_GEO_PHARMACIES_REQUEST';
export const GET_GEO_PHARMACIES_SUCCESS = 'GET_GEO_PHARMACIES_SUCCESS';
export const GET_GEO_PHARMACIES_FAILURE = 'GET_GEO_PHARMACIES_FAILURE';

export const SET_SELECTED_PHARMACY_ID = 'SET_SELECTED_PHARMACY_ID';
export const SET_MAP_CAMERA = 'SET_MAP_CAMERA';


// ---------------------------------------------------------------------------------------------- //
// Fetch
// ---------------------------------------------------------------------------------------------- //

export const POST_FAVORITE_PHARMACY_REQUEST = 'POST_FAVORITE_PHARMACY_REQUEST';
export const POST_FAVORITE_PHARMACY_SUCCESS = 'POST_FAVORITE_PHARMACY_SUCCESS';
export const POST_FAVORITE_PHARMACY_FAILURE = 'POST_FAVORITE_PHARMACY_FAILURE';

export const DELETE_FAVORITE_PHARMACY_REQUEST = 'DELETE_FAVORITE_PHARMACY_REQUEST';
export const DELETE_FAVORITE_PHARMACY_SUCCESS = 'DELETE_FAVORITE_PHARMACY_SUCCESS';
export const DELETE_FAVORITE_PHARMACY_FAILURE = 'DELETE_FAVORITE_PHARMACY_FAILURE';

export const SET_PHARMACY_ID = 'SET_PHARMACY_ID';

export const SET_PHARMACY_NAME = 'SET_PHARMACY_NAME';
