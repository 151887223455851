// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import {
    GET_METRICS_SUCCESS,
    GET_METRICS_REQUEST,
    GET_METRICS_FAILURE,
    GET_RANKS_SUCCESS,
    GET_RANKS_REQUEST,
    GET_RANKS_FAILURE,
    SET_PREVIOUS_METRICS,
    SET_NEXT_METRICS,
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

export type chartsReducerType = {
    list: [],
    currentOptions: {},
    currentIndex: number,
    pending: boolean,
    error: any,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
    list: [],
    currentOptions: {},
    index: 0,
    pending: false,
    error: null,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

const chartsReducer = (
    state: chartReducerType = initialState,
    action: Action
) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case GET_METRICS_REQUEST:
        case GET_RANKS_REQUEST:
            newState.pending = true;
            return newState;
        case GET_RANKS_FAILURE:
        case GET_METRICS_FAILURE:
            newState.error = action.payload;
            newState.pending = false;
            return newState;
        case GET_METRICS_SUCCESS:
            newState.list = action.payload.list;
            newState.currentOptions = action.payload.currentOptions;
            newState.pending = false;
            return newState;
        case SET_PREVIOUS_METRICS:
        case SET_NEXT_METRICS:
            newState.currentOptions = action.payload.currentOptions;
            newState.index = action.payload.index;
            return newState;
        case GET_RANKS_SUCCESS:
            newState.currentOptions = action.payload;
            newState.pending = false;
            return newState;
        default:
            return newState;
    }
}

export default chartsReducer;
