// @flow

// ---------------------------------------------------------------------------------------------- //
// Types
// ---------------------------------------------------------------------------------------------- //

import { Claims } from "./types";

// ---------------------------------------------------------------------------------------------- //
// Fetch
// ---------------------------------------------------------------------------------------------- //

export const fetchCollection = (
  query,
  update: Object[] => void
): () => void => {
  return query.onSnapshot((querySnapshot) => {
    // Init results list
    const results = [];
    // Stop if no snapshot
    if (!querySnapshot) return;
    // Get data for each document
    querySnapshot.forEach((document) => results.push({ ...document.data(), id: document.id }));
    // Callback
    update(results);
  });
};

export const fetchDocument = (
  documentReference: any,
  update: Object => void
): any => {
  return documentReference.onSnapshot((snapshot) => {
    if (snapshot)
      update(snapshot.data())
  });
};

// ---------------------------------------------------------------------------------------------- //
// Auth
// ---------------------------------------------------------------------------------------------- //

export const login = async (
  firebase: any,
  email: string,
  password: string
): Promise<any> => {
  try {
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const loginAnonymously = async (firebase: any): Promise<any> => {
  try {
    const userCredential = await firebase.auth().signInAnonymously();
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const logout = async (firebase: any): Promise<any> => {
  try {
    await firebase.auth().signOut();
  } catch (error) {
    throw error;
  }
};

export const signup = async (firebase: any, email: string, password: string): Promise<any> => {
  try {
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const verifyPhoneNumber = async (
  firebase: any,
  phone: string
): Promise<any> => {
  try {
    const phoneAuthSnapshot = await firebase.auth().verifyPhoneNumber(phone);
    return phoneAuthSnapshot;
  } catch (error) {
    console.error('verify phone failed', error);
    throw error;
  }
};

export const linkPhoneNumber = async (firebase: any, verificationId: string, verificationCode: string): Promise<any> => {
  try {
    // Build phone credentials
    const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    // Link them
    const userCredential = await firebase.auth().currentUser.linkWithCredential(credential);

    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const signupPhoneNumber = async (firebase: any, verificationId: string, verificationCode: string): Promise<any> => {
  try {
    // Build phone credentials
    const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    const userCredential = await firebase.auth().signInWithCredential(credential);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const getFirebaseUser = async (firebase: any): Promise<any> => {
  try {
    if (!firebase)
      throw ('param firebase missing')
    return new Promise((resolve) => {
      onAuth(firebase, (firebaseUser) => {
        resolve(firebaseUser);
      });
    });
  } catch (error) {
    throw error;
  }
};

export const onAuth = (
  firebase: any,
  listener: (any) => void
) => firebase.auth().onAuthStateChanged(listener);

export const getUserId = async (
  firebase: any
): Promise<string> => {
  try {
    const firebaseUser = await getFirebaseUser(firebase);
    return firebaseUser ? firebaseUser.uid : null;
  } catch (error) {
    throw error;
  }
};

export const getIdTokenResult = async (
  firebase: any,
  user: any,
  forceRefresh: boolean
): Promise<any> => {
  // Get user if not provided
  const aUser = user || await getFirebaseUser(firebase);
  // Stop if no user
  if (!aUser) return null;
  // Get token otherwise
  return aUser.getIdTokenResult(forceRefresh);
};

export const getClaims = async (
  firebase: any,
  user: any,
  forceRefresh: boolean
): Promise<Claims> => {
  try {
    // Get idTokenResult
    const idTokenResult = await getIdTokenResult(firebase, user, forceRefresh);
    // Grab claims
    return idTokenResult ? idTokenResult.claims : null;
  } catch (error) {
    throw error;
  }
};

export const getToken = async (
  firebase: any,
  user: any,
  forceRefresh: boolean
): Promise<string> => {
  try {
    // Get idTokenResult
    const idTokenResult = await getIdTokenResult(firebase, user, forceRefresh);
    // Grab token
    return idTokenResult ? idTokenResult.token : null;
  } catch (error) {
    throw error;
  }
};

export const sendPasswordResetEmail = async (
  firebase: any,
  email: string
): Promise<any> => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
  } catch (error) {
    throw error;
  }
};

export const getDeviceId = async (
  firebase: any
): Promise<string> => {
  try {
    return firebase.iid ? firebase.iid().get() : null;
  } catch (error) {
    throw error;
  }
};

// ---------------------------------------------------------------------------------------------- //
// Perfomance
// ---------------------------------------------------------------------------------------------- //

export const startTraceAPI = async (
  firebase: any,
  method: string,
  url: string,
  userId: string,
  claims: any
) => {
  try {
    // Define the network metric
    const metric = await firebase.perf().newHttpMetric(url, method.toUpperCase());
    // Define meta details
    if (userId) metric.putAttribute('userId', userId);
    if (claims.isPharmacy) metric.putAttribute('isPharmacy', claims.isPharmacy);
    if (claims.pharmacyId) metric.putAttribute('pharmacyId', claims.pharmacyId);
    return metric;
  } catch (e) {
    console.warn(e);
  }
};

export const stopTraceAPI = async (
  metric: any,
  response: any
) => {
  try {
    // Store trace
    metric.setHttpResponseCode(response.status);
    metric.setResponseContentType(response.headers['content-type']);
    metric.setResponsePayloadSize(Number(response.headers['content-length']));
    // Stop the trace
    await metric.stop();
  } catch (e) {
    console.warn(e);
  }
};

// ---------------------------------------------------------------------------------------------- //
// Timestamp
// ---------------------------------------------------------------------------------------------- //

export const convertDate = (Timestamp, object) => {
  // Stop if not an object
  if (!(object instanceof Object)) return;
  Object.keys(object).forEach(key => {
    const value = object[key];
    if (value instanceof Timestamp) {
      object[key] = value.toMillis();
    } else if (value instanceof Object) {
      object[key] = convertDate(Timestamp, value);
    }
  });
  return object;
};

export const convertDateArray = (Timestamp, objects) => objects.map(object => convertDate(Timestamp, object));
