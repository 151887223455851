// ---------------------------------------------------------------------------------------------- //
// Scans
// ---------------------------------------------------------------------------------------------- //

export const FETCH_ITEMS = 'FETCH_ITEMS';
export const UPDATE_ITEMS = 'UPDATE_ITEMS';
export const ADD_ITEMS = 'ADD_ITEMS';
export const RESET_ITEMS = 'RESET_ITEMS';

// ---------------------------------------------------------------------------------------------- //
// Scan
// ---------------------------------------------------------------------------------------------- //

export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_REQUEST = 'GET_ITEM_REQUEST';
export const GET_ITEM_FAILURE = 'GET_ITEM_FAILURE';

export const CUSTOM_CLAIMS_REQUEST = 'CUSTOM_CLAIMS_REQUEST';
export const CUSTOM_CLAIMS_SUCCESS = 'CUSTOM_CLAIMS_SUCCESS';
export const CUSTOM_CLAIMS_FAILURE = 'CUSTOM_CLAIMS_FAILURE';

export const UPDATE_SCANS = 'UPDATE_SCANS';
