// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  GET_LOT_REQUEST,
  GET_LOT_SUCCESS,
  GET_LOT_FAILURE,
  FETCH_LOTS,
  UPDATE_LOTS,
  CUSTOM_CLAIMS_REQUEST,
  CUSTOM_CLAIMS_SUCCESS,
  CUSTOM_CLAIMS_FAILURE,
  UPDATE_SCANS,
  ADD_LOTS,
  RESET_LOTS
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Lot
} from "../../../utils/types";

import type {
  Action
} from "redux";

export type lotsReducerType = {
  list: Lot[],
  lot: Lot,
  pending: boolean,
  unsubscribe: any
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  lot: null,
  pending: false,
  unsubscribe: null,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: lotsReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case GET_LOT_REQUEST:
      newState.pending = true;
      return newState;
    case GET_LOT_SUCCESS:
      newState.lot = action.payload;
      newState.pending = false;
      return newState;
    case GET_LOT_FAILURE:
      newState.pending = false;

    case CUSTOM_CLAIMS_REQUEST:
      newState.pending = true;
      return newState;

    case CUSTOM_CLAIMS_SUCCESS:
    case CUSTOM_CLAIMS_FAILURE:
      newState.pending = false;
      return newState;

    case FETCH_LOTS:
      // Start spinning
      newState.pending = true;
      // Clear list
      newState.list = [];
      // Unsubscribe previous fetch if exist
      if (newState.unsubscribe) newState.unsubscribe();
      // Store new unSubscribe
      newState.unsubscribe = action.payload;
      return newState;

    case UPDATE_LOTS:
      // Stop spinning
      newState.pending = false;
      // Store new list
      newState.list = action.payload ? action.payload : [];
      return newState;

    case RESET_LOTS:
      // Clear list
      newState.list = [];
      return newState;

    case ADD_LOTS:
      // Stop spinning
      newState.pending = false;
// Store new list
      newState.list = [...newState.list, ...action.payload];
      return newState;

    default:
      return newState;
  }
};
