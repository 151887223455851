// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  CUSTOM_CLAIMS_REQUEST,
  CUSTOM_CLAIMS_SUCCESS,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  FETCH_PRODUCTS,
  UPDATE_PRODUCTS,
  CUSTOM_CLAIMS_FAILURE,
  ADD_PRODUCTS, RESET_PRODUCTS
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Product
} from "../../../utils/types";
import type {
  Action
} from "redux";

export type productsReducerType = {
  list: Product[],
  product: Product,
  pending: boolean,
  unsubscribe: any,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  product: null,
  pending: false,
  unsubscribe: null,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: productsReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case GET_PRODUCT_REQUEST:
      newState.pending = true;
      return newState;
    case GET_PRODUCT_SUCCESS:
      newState.product = action.payload;
      newState.pending = false;
      return newState;
    case GET_PRODUCT_FAILURE:
      newState.pending = false;
      newState.error = action.payload;

    case CUSTOM_CLAIMS_REQUEST:
      newState.pending = true;
      return newState;

    case CUSTOM_CLAIMS_SUCCESS:
    case CUSTOM_CLAIMS_FAILURE:
      newState.pending = false;
      return newState;

    case RESET_PRODUCTS:
      // Clear list
      newState.list = [];
      return newState;

    case FETCH_PRODUCTS:
      // Start spinning
      newState.pending = true;
      return newState;

    case UPDATE_PRODUCTS:
      // Stop spinning
      newState.pending = false;
      // Store new list
      newState.list = action.payload ? action.payload : [];
      return newState;

    case ADD_PRODUCTS:
      // Stop spinning
      newState.pending = false;
      // Store new list
      newState.list = [...newState.list, ...action.payload];
      return newState;

    default:
      return newState;
  }
};

