// ---------------------------------------------------------------------------------------------- //
// Scans
// ---------------------------------------------------------------------------------------------- //

export const FETCH_WAREHOUSES = 'FETCH_WAREHOUSES';
export const UPDATE_WAREHOUSES = 'UPDATE_WAREHOUSES';

// ---------------------------------------------------------------------------------------------- //
// Scan
// ---------------------------------------------------------------------------------------------- //

export const GET_WAREHOUSE_REQUEST = 'GET_WAREHOUSE_REQUEST';
export const GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS';
export const GET_WAREHOUSE_FAILURE = 'GET_WAREHOUSE_FAILURE';
