// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

import * as apiCore from '../../shared/utils/api';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import * as firebase from './firebase';

// ---------------------------------------------------------------------------------------------- //
// Settings
// ---------------------------------------------------------------------------------------------- //

// Config dotenv
require('dotenv').config();

// ---------------------------------------------------------------------------------------------- //
// API
// ---------------------------------------------------------------------------------------------- //

export const api = async (
  url: string,
  method: string,
  cancellationToken: any,
  params: any = {},
  unauth: boolean,
) => {
  return apiCore.api(firebase, process.env.BASE_URL, url, method, cancellationToken, params, unauth);
}

export const cancelToken = () => apiCore.cancelToken();
