// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';

// Type
import { ThunkAction } from 'redux-thunk';

// UI
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  withStyles,
  Divider,
} from '@material-ui/core';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// UI
import Spinner from "../../../ui/Spinner";

// Colors
import {
  green,
  red
} from "../../../../shared/styles/colors";

// Icons
import {
  LotsIcon,
  LegitIcon,
  WarningIcon
} from "../../../styles/icons";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  user: {},
  pending: boolean,
  GetLot: ThunkAction,
};

// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  card: {
    maxWidth: 600,
    margin: theme.spacing(3),
  },
  ok: {
    color: green
  },
  warning: {
    color: red,
  },
});

// ---------------------------------------------------------------------------------------------- //
// Component
// ---------------------------------------------------------------------------------------------- //

class LotDetail extends React.Component<Props, State> {
  // -------------------------------------------------------------------------------------------- //
  // Life Cycle
  // -------------------------------------------------------------------------------------------- //

  constructor(props) {
    super(props);
  }

  render() {
    const { lot, pending, classes } = this.props;

    // Return null if empty
    if (!lot) return null;

    // Grab values otherwise
    const {
      lot: lotNum,
      legit,
      warning,
    } = lot;

    return (
      <div>
        { pending &&
          <Card className={classes.card}>
            <Spinner spinning={pending}/>
          </Card>
        }
        { !pending &&
          <Card className={classes.card }>
            <CardHeader
              avatar={
                <Avatar className={classes.avatar}>
                  <LotsIcon />
                </Avatar >
              }
              title={`Lot ${lotNum}`}
              titleTypographyProps={{ 'variant': 'h5', 'color': 'textSecondary' }}
            />
            <Divider/>
            <CardContent>
              <List >
                {legit !== undefined &&
                <ListItem>
                  <ListItemIcon>
                    <LegitIcon/>
                  </ListItemIcon>
                  <ListItemText
                    primary={legit ? 'Numéro authentique' : 'Numéro inconnu'}
                    className={legit ? classes.ok : classes.warning}
                  />
                </ListItem>
                }
                {warning !== undefined &&
                <ListItem>
                  <ListItemIcon>
                    <WarningIcon/>
                  </ListItemIcon>
                  <ListItemText
                    primary={warning ? 'Risque identifié' : 'Pas de risque identifié' }
                    className={warning ? classes.warning : classes.ok}
                  />
                </ListItem>
                }
              </List>
            </CardContent>
          </Card>
        }
    </div>
    );
  }
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default withStyles(styles)(LotDetail);
