// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import * as firebase from '../../../utils/firebase';
import * as api from '../../../utils/api';
import * as actions from "../../../../shared/modules/auth/redux/actions";

// ---------------------------------------------------------------------------------------------- //
// Functions
// ---------------------------------------------------------------------------------------------- //

export const SignUp = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
) => actions.SignUp(firebase, api, email, password, firstName, lastName);

export const LogIn = (
  email: string,
  password: string,
) => actions.LogIn(firebase, api, email, password);

export const LogOut = () => actions.LogOut(firebase);

export const IsLogged = () => actions.IsLogged(firebase);

export const LogInAnonymously = () => actions.LogInAnonymously(firebase);

export const SendPasswordResetEmail = (
  email: string,
) => actions.SendPasswordResetEmail(firebase, email);
