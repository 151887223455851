// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {
  AppBar, 
  Tabs,
  Tab,
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Type
import { ThunkAction } from 'redux-thunk';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import {
  GetScan,
} from "../redux/actions";

// UI
import Spinner from "../../../ui/Spinner";
import ScanDetail from "../ui/ScanDetail";
import LotDetail from "../../lots/ui/LotDetail";
import TabPanel from "../ui/TabPanel"

import ScanResult from "./ScanResult"
// import SwipeableViews from 'react-swipeable-views'

// Type
import type { Scan } from "../../../../shared/utils/types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  classes: {},
  scan: Scan,
};

type State = {
};

// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'scroll',
  },
  tabLabel: {
    fontSize: '4vw',
  },
  tabs: {
  },
  tabPanel: {
    margin: "0",
  }
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

// ---------------------------------------------------------------------------------------------- //
// Components
// ---------------------------------------------------------------------------------------------- //

function ScanDocument(props) {
  
  if (props) console.log("props ScanDocument = ", props)
  
  // ---------------------------------------------------------------------------------------------- //
  // Constuctor hook
  // ---------------------------------------------------------------------------------------------- //

  const classes = useStyles();
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0)

  // ---------------------------------------------------------------------------------------------- //
  // Handler
  // ---------------------------------------------------------------------------------------------- //

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  // ---------------------------------------------------------------------------------------------- //
  // Render
  // ---------------------------------------------------------------------------------------------- //

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs className={classes.tabs} 
          value={currentTab}
          onChange={handleChange}
          variant="fullWidth">
          <Tab className={classes.tabLabel} label="Résultat" index={0} {...a11yProps(0)}/>
          <Tab className={classes.tabLabel} label="Notice" index={1} {...a11yProps(1)}/>
        </Tabs>
      </AppBar>
      <TabPanel value={currentTab} index={0} className={classes.tabPanel}><ScanResult {...props}/></TabPanel>
      <TabPanel value={currentTab} index={1}></TabPanel>
    </div>
  )
}

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

const mapStateToProps = ({ pharmacies, scans }) => {
  const { pending } = pharmacies;
  const { scan } = scans;
  return {
    scan,
  };
};

const mapDispatchToProps = {
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ScanDocument);
