// ---------------------------------------------------------------------------------------------- //
// All
// ---------------------------------------------------------------------------------------------- //

export const SEARCH_ALL_REQUEST = 'SEARCH_ALL_REQUEST';
export const SEARCH_ALL_SUCCESS = 'SEARCH_ALL_SUCCESS';
export const SEARCH_ALL_FAILURE = 'SEARCH_ALL_FAILURE';

// ---------------------------------------------------------------------------------------------- //
// Pharmacies
// ---------------------------------------------------------------------------------------------- //

export const SEARCH_PHARMACIES_REQUEST = 'SEARCH_PHARMACIES_REQUEST';
export const SEARCH_PHARMACIES_SUCCESS = 'SEARCH_PHARMACIES_SUCCESS';
export const SEARCH_PHARMACIES_FAILURE = 'SEARCH_PHARMACIES_FAILURE';