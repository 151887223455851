// @flow

export const FETCH_PATIENTS = 'FETCH_PATIENTS';
export const UPDATE_PATIENTS = 'UPDATE_PATIENTS';

export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
export const GET_PATIENT_REQUEST = 'GET_PATIENT_REQUEST';
export const GET_PATIENT_FAILURE = 'GET_PATIENT_FAILURE';

export const SET_PATIENT = 'SET_PATIENT';

export const CUSTOM_CLAIMS_REQUEST = 'CUSTOM_CLAIMS_REQUEST';
export const CUSTOM_CLAIMS_SUCCESS = 'CUSTOM_CLAIMS_SUCCESS';
export const CUSTOM_CLAIMS_FAILURE = 'CUSTOM_CLAIMS_FAILURE';

