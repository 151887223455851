// ---------------------------------------------------------------------------------------------- //
// Scans
// ---------------------------------------------------------------------------------------------- //

export const GET_LOTS_SUCCESS = 'GET_LOTS_SUCCESS';
export const GET_LOTS_REQUEST = 'GET_LOTS_REQUEST';
export const GET_LOTS_FAILURE = 'GET_LOTS_FAILURE';

export const FETCH_LOTS = 'FETCH_LOTS';
export const UPDATE_LOTS = 'UPDATE_LOTS';
export const ADD_LOTS = 'ADD_LOTS';
export const RESET_LOTS = 'RESET_LOTS';

// ---------------------------------------------------------------------------------------------- //
// Scan
// ---------------------------------------------------------------------------------------------- //

export const GET_LOT_SUCCESS = 'GET_LOT_SUCCESS';
export const GET_LOT_REQUEST = 'GET_LOT_REQUEST';
export const GET_LOT_FAILURE = 'GET_LOT_FAILURE';

export const CUSTOM_CLAIMS_REQUEST = 'CUSTOM_CLAIMS_REQUEST';
export const CUSTOM_CLAIMS_SUCCESS = 'CUSTOM_CLAIMS_SUCCESS';
export const CUSTOM_CLAIMS_FAILURE = 'CUSTOM_CLAIMS_FAILURE';

export const UPDATE_SCANS = 'UPDATE_SCANS';
