// @flow

// ---------------------------------------------------------------------------------------------- //
// Check
// ---------------------------------------------------------------------------------------------- //

const PASSWORD_REGEX = /^.{8,}$/
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/

export {
  PASSWORD_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
};

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

export const CANCEL = 'CANCEL';
export const RESET_STATE = 'RESET_STATE';
