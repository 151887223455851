// @flow


// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

import { startOfDay, endOfDay } from 'date-fns';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions


// Action types
import {
  GET_SCAN_REQUEST,
  GET_SCAN_SUCCESS,
  GET_SCAN_FAILURE,
  FETCH_SCANS,
  UPDATE_SCANS,
  POST_SCAN_FAILURE,
  POST_SCAN_REQUEST,
  POST_SCAN_SUCCESS,
  CLEAR_SCAN,
  FETCH_SCANS_FAILURE,
  RESET_COUNTER,
  SHOULD_BEEP, CLEAR_SCAN_ERROR, ADD_TO_SERIALS
} from "./action-types";
import {
  CANCEL
} from "../../../utils/constants";

// Utils
import {
  getUniqueAndCountDuplicate
} from "../../../utils/arrays";
import {
  parseBarCode
} from "../../../utils/parsers";

// Errors
import {
  Errors
} from "../../../utils/enums";

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //


// ---------------------------------------------------------------------------------------------- //
// Post
// ---------------------------------------------------------------------------------------------- //

export const PostScan = (
  api: any,
  barcode: string,
  type: string,
  device: string,
  lat: number,
  lng: number,
  accuracy: number,
  altitude: number,
  altitudeAccuracy: number,
  speed: number,
  heading: number,
  onboard: boolean,
  serials: string[],
) => async dispatch => {
  try {
    dispatch({
      type: POST_SCAN_REQUEST
    });
    // 1- parse barcode
    const parsed = parseBarCode(barcode, type);
    console.log(parsed.serial);
    // 2- Compare to local list if provided
    if (parsed.serial && serials) {
      if (serials.includes(parsed.serial)) {
        dispatch({
          type: POST_SCAN_FAILURE,
          payload: Errors.already_scanned
        });
        return;
      } else {
        dispatch({
          type: ADD_TO_SERIALS,
          payload: parsed.serial
        });
      }
    }
    // 4- Beep
    dispatch({
      type: SHOULD_BEEP,
      payload: true,
    });
    // 5- Post scan
    const scan = await api.api(
      "v1/scans",
      "post",
      null,
      {
        barcode,
        type,
        device,
        lat,
        lng,
        accuracy,
        altitude,
        altitudeAccuracy,
        speed,
        heading,
        onboard
      },
      false
    );
    // Successfull operation
    dispatch({
      type: POST_SCAN_SUCCESS,
      payload: scan
    });
  } catch (error) {
    // Don't do anything if cancel error
    if (error.message === CANCEL) return;
    // Dispatch otherwise
    dispatch({
      type: POST_SCAN_FAILURE,
      payload: error.response
    });
  }
};

export const GetScan = (
  api: any,
  scanId: string,
): any => async dispatch => {
  try {
    dispatch({
      type: GET_SCAN_REQUEST,
    });
    // Call API
    const scan = await api.api(
      `v1/scans/${scanId}`,
      "get",
      null,
      {},
      false
    );
    // Successfull operation
    dispatch({
      type: GET_SCAN_SUCCESS,
      payload: scan
    });
  } catch (error) {
    // Don't do anything if cancel error
    if (error.message === CANCEL) return;
    // Dispatch otherwise
    dispatch({
      type: GET_SCAN_FAILURE,
      payload: error.response
    });
  }
};

// ---------------------------------------------------------------------------------------------- //
// Fetch
// ---------------------------------------------------------------------------------------------- //

export const FetchScans = (
    firebase: any,
    from?: Date,
    to?: Date,
    itemId?: string,
    lotId?: string,
    productId?: string,
    userId?: string,
    pharmacyId?: string,
    limit?: number,
    orderBy?: string,
    direction?: string,
    startAfter?: any,
) => async dispatch => {
  try {
      // Start spinning
      /*dispatch({
        type: FETCH_SCANS,
      });*/
      // Get collection reference according to claims
      //const reference = await getCollectionReferenceFromClaims(firebase, 'scans');
      const reference = await firebase.collectionReference('scans');
      // Init query pagination
      let query = reference.orderBy(orderBy || 'createdAt', direction || 'desc').limit(limit || 20);
      // Add cursor if provided
      if (startAfter) query = query.startAfter(startAfter);
      // Add query filters if provided
      if (from && to) query = query.where('createdAt', '>=', startOfDay(from)).where('createdAt', '<=', endOfDay(to));
//      if (itemId) query = query.where('item.id', '==', itemId);
      if (lotId) query = query.where('lot.id', '==', lotId);
      if (productId) query = query.where('product.id', '==', productId);
      if (userId) query = query.where('user', '==', userId);
      if (pharmacyId) query = query.where('pharmacy', '==', pharmacyId);

      // Fetch and get unsubscribe function
      const unsubscribe = await firebase.fetchCollection(query, (scans  => {
        // Convert timestamp
        scans = firebase.convertDateArray(scans);
        // Extract items, lots and products from scans
        const itemList = scans.filter(scan => scan.item).map(scan => scan.item);
        const lotList = scans.filter(scan => scan.lot).map(scan => scan.lot);
        const productList = scans.filter(scan => scan.product).map(scan => scan.product);
        // Get unique values and count scans
        const items = getUniqueAndCountDuplicate(itemList);
        const lots = getUniqueAndCountDuplicate(lotList);
        const products = getUniqueAndCountDuplicate(productList);
        // Dispatch results
        dispatch({
          type: UPDATE_SCANS,
          payload: {scans, items, lots, products}
        });
      }));
      // Store unsubscribe
      dispatch({
        type: FETCH_SCANS,
        payload: unsubscribe,
      });
    } catch (error) {
        console.warn('error', error);
        dispatch({
            type: FETCH_SCANS_FAILURE,
            payload: error
        })
    }
};

export const FetchItemScans = (
  firebase: any,
  itemId?: string,
) => async dispatch => {
  try {
    // Get collection reference according to claims
    const reference = await firebase.collectionReference('items').doc(itemId).collection("scans").orderBy("createdAt", 'asc').limit(100);
    // Fetch and get unsubscribe function
    const unsubscribe = await firebase.fetchCollection(reference, (scans  => {
      // Dispatch results
      dispatch({
        type: UPDATE_SCANS,
        payload: {scans: firebase.convertDateArray(scans)}
      });
    }));
    // Store unsubscribe
    dispatch({
      type: FETCH_SCANS,
      payload: unsubscribe,
    });
  } catch (error) {
    console.warn('error', error);
    dispatch({
      type: FETCH_SCANS_FAILURE,
      payload: error
    })
  }
};

export const ClearScan = (
): any => async dispatch => {
  dispatch({
    type: CLEAR_SCAN,
  });
};

export const ClearScanError = (
): any => async dispatch => {
  dispatch({
    type: CLEAR_SCAN_ERROR,
  });
};

export const ResetCounter = (
): any => async dispatch => {
  dispatch({
    type: RESET_COUNTER,
  });
};

export const ShouldBeep = (
  shouldBeep: boolean,
): any => async dispatch => {
  dispatch({
    type: SHOULD_BEEP,
    payload: shouldBeep
  });
};
