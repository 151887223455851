// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import { compose } from 'redux';

// UI
import SvgIcon from '@material-ui/core/SvgIcon';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';
import { ThunkAction } from 'redux-thunk';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  classes: {},
};


// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  icon: {
    margin: theme.spacing(2),
  },
});


// ---------------------------------------------------------------------------------------------- //
// Function
// ---------------------------------------------------------------------------------------------- //

function WarehousesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M13.0169682,19.0020456 L16.973401,19.0020456 L16.973401,15.0393198 L13.0169682,15.0393198 L13.0169682,19.0020456 Z M7.02269227,18.9986297 L10.985541,18.9986297 L10.985541,15.0467093 L7.02269227,15.0467093 L7.02269227,18.9986297 Z M10.0184703,13.0064395 L13.9834112,13.0064395 L13.9834112,9.05437968 L10.0184703,9.05437968 L10.0184703,13.0064395 Z M3.00342469,21 L3.00342469,20.6321283 C3.00342469,16.5665071 3.00830642,12.5008162 3.00000748,8.43519506 C2.99791531,7.42778266 3.43455117,6.74411416 4.35936,6.33762176 C6.81221998,5.25945138 9.25915214,4.16754767 11.7051777,3.07383145 C11.9118143,2.98139294 12.0800248,2.97191207 12.2930077,3.06727849 C14.7388241,4.16238896 17.1881273,5.24976137 19.6395925,6.3321842 C20.5581946,6.73777035 21.0022926,7.42262395 20.9999911,8.43296427 C20.9905764,12.4985854 20.9960858,16.5642763 20.9960858,20.6298975 L20.9960858,21 L3.00342469,21 Z" id="Shape"></path>
    </SvgIcon>
  );
}

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  //withStyles(styles),
)(WarehousesIcon);