// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  GET_SUPPLIER_REQUEST,
  GET_SUPPLIER_SUCCESS,
  GET_SUPPLIER_FAILURE,
  CUSTOM_CLAIMS_REQUEST,
  CUSTOM_CLAIMS_SUCCESS,
  CUSTOM_CLAIMS_FAILURE,
  FETCH_SUPPLIERS,
  UPDATE_SUPPLIERS,
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Supplier
} from "../../../utils/types";
import type {
  Action
} from "redux";

export type suppliersReducerType = {
  list: Supplier[],
  supplier: Supplier,
  pending: boolean,
  unsubscribe: any,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  supplier: null,
  pending: false,
  unsubscribe: null,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: suppliersReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case GET_SUPPLIER_REQUEST:
    case CUSTOM_CLAIMS_REQUEST:
      newState.pending = true;
      return newState;
    case GET_SUPPLIER_SUCCESS:
      newState.supplier = action.payload;
      newState.pending = false;
      return newState;

    case GET_SUPPLIER_FAILURE:
    case CUSTOM_CLAIMS_SUCCESS:
    case CUSTOM_CLAIMS_FAILURE:
      newState.pending = false;
      return newState;

    case FETCH_SUPPLIERS:
      // Start spinning
      newState.pending = true;
      // Clear list
      newState.list = [];
      // Unsubscribe previous fetch if exist
      if (newState.unsubscribe) newState.unsubscribe();
      // Store new unSubscribe
      newState.unsubscribe = action.payload;
      return newState;

    case UPDATE_SUPPLIERS:
      // Stop spinning
      newState.pending = false;
      // Store new list
      newState.list = action.payload ? action.payload : [];

    default:
      return newState;
  }
};
