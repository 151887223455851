// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

import React, { Component } from 'react';
import {
  Link,
  withStyles,
} from '@material-ui/core';

// ---------------------------------------------------------------------------------------------- //
// Function
// ---------------------------------------------------------------------------------------------- //

const ButtonLink = ({text}) => (
  <Link
    component="button"
    variant="body2"
    m={2}
  >
    {text}
  </Link>
);

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default ButtonLink;
