// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

// Type
import {ThunkAction} from "redux-thunk";

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Routes
import * as Routes from './shared/routes';

// UI
import Login from './shared-web/modules/auth/components/Login';
import Signup from './shared-web/modules/auth/components/Signup';
import Dashboard from './ui/Dashboard';
import Spinner from "./shared-web/ui/Spinner";

// Actions
import {
  IsLogged,
} from "./shared-web/modules/auth/redux/actions";
import ScanDocument from './shared-web/modules/scans/components/ScanDocument';
import ScanCamera from './shared-web/components/ScanCamera';


// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  classes: {},
  IsLogged: ThunkAction,
  pending: boolean,
};

type State = {
};

// ---------------------------------------------------------------------------------------------- //
// Style
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

// ---------------------------------------------------------------------------------------------- //
// Component
// ---------------------------------------------------------------------------------------------- //

class App extends React.Component<Props, State>  {

  // -------------------------------------------------------------------------------------------- //
  // Life cycle
  // -------------------------------------------------------------------------------------------- //

  componentDidMount(): void {
    // Check if user is logged
    this.props.IsLogged();
  }

  // -------------------------------------------------------------------------------------------- //
  // Render
  // -------------------------------------------------------------------------------------------- //

  render() {
    // Grag props
    const { isLogged, pending, classes } = this.props;
    // Display spinner if checking if user is logged

    if (pending) return (
      <div className={classes.root}>
        <Spinner spinning={pending}/>
      </div>
    );

    // Return login or signup if user is not logged
    if (!isLogged) {
      return (
        <Switch>
          <Route path={Routes.scan} render={() => <ScanDocument/>}></Route>
          <Route path={Routes.scanner} render={() => <ScanCamera/>}></Route>
          {/* <Route path={Routes.signup} component={Signup} /> */}
          <Route path="" component={Dashboard} />
        </Switch>
      )
    }
    // otherwise return route
    return (
      <Switch>
        <Route path="" component={Dashboard} />
      </Switch>
    )
  }
}

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

const mapStateToProps = ({ auth }) => {
  const { isLogged, pending, claims } = auth;
  return {
    isLogged,
    pending,
    claims,
  };
};

const mapDispatchToProps = {
  IsLogged,
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(App);
