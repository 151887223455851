// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';

// UI
import {
  SvgIcon,
  IconButton
} from '@material-ui/core';



// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  classes: {},
};


// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  icon: {
    margin: theme.spacing(2),
  },
});


// ---------------------------------------------------------------------------------------------- //
// Function
// ---------------------------------------------------------------------------------------------- //

function CustomsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18.7011522,17.7615 C15.9116087,17.7615 13.1432609,17.7615 10.3477826,17.7615 C10.3477826,17.1504131 10.3477826,16.5510652 10.3477826,15.9154565 C12.5164565,15.9154565 14.6730652,15.9154565 16.8521739,15.9154565 C16.8521739,15.5931521 16.8521739,15.3064565 16.8521739,14.9837609 C14.9819348,14.9837609 13.1192609,14.9837609 11.1816522,14.9837609 C11.2883478,14.8462826 11.3456739,14.7628043 11.4124565,14.6876739 C13.4259783,12.422087 15.4432174,10.1596956 17.4498261,7.8880435 C17.6214782,7.69376087 17.742913,7.69271739 17.931913,7.83567391 C18.3988043,8.18889128 18.7180435,8.63967389 18.7211087,9.23113041 C18.7356522,12.0341739 18.7296521,14.8373478 18.7299131,17.6404565 C18.7299783,17.670913 18.7159565,17.7013696 18.7011522,17.7615 Z" id="Path"></path>
      <path d="M5.65871739,14.9686957 C6.36019565,14.0362174 7.01236957,13.1718913 7.66180435,12.3055435 C8.67071739,10.9595217 9.67773913,9.61213046 10.6855435,8.26532609 C10.9999565,7.84513041 11.4176087,7.54265217 11.9360217,7.52908696 C13.2367826,7.49497826 14.5390435,7.51780435 15.9157826,7.51780435 C14.0522609,9.69234785 12.2236304,11.8260652 10.334087,14.0309348 C10.334087,13.4553261 10.334087,12.9736305 10.334087,12.3977608 C10.217413,12.534913 10.1553913,12.5981739 10.1043261,12.6693261 C9.60482606,13.3661087 9.11093478,14.067 8.60465215,14.7588261 C8.53702177,14.8512391 8.4105,14.9576739 8.30941302,14.9595652 C7.45291305,14.9755435 6.59608695,14.9686957 5.65871739,14.9686957 Z" id="Path"></path>
      <path d="M16.9244348,2.86121739 C16.8795652,3.38276087 16.8918261,3.90065218 16.7820652,4.39121739 C16.4681087,5.79469565 15.2111087,6.66469565 13.7536957,6.54078261 C12.4443261,6.42945652 11.3859783,5.31854348 11.2738044,3.93828261 C11.2703478,3.89536957 11.2619348,3.85291305 11.2498695,3.76819565 C10.9777174,3.76819565 10.7032174,3.76819565 10.4287174,3.76819565 C10.4162609,3.74217392 10.4038043,3.71615218 10.3913478,3.69013044 C10.6704131,3.4121087 10.9467391,3.13121739 11.2318695,2.85958696 C11.270087,2.82319565 11.3531087,2.82417392 11.4153261,2.82410869 C13.1958913,2.82286957 14.9764565,2.82365217 16.7570217,2.82508695 C16.7878043,2.82515218 16.8183913,2.83780435 16.9244348,2.86121739 Z" id="Path"></path>
      <path d="M1.86652174,15.9560217 C1.65345652,15.9328043 1.43869565,15.9196304 1.22758695,15.8845435 C0.551804348,15.7723695 0.062673913,15.2439783 0.0567391304,14.5651956 C0.0427173913,12.969587 0.0373695652,11.3735217 0.0578478261,9.7780435 C0.0695869565,8.86434785 0.83106522,8.32969565 1.88973913,8.46143476 C1.89547826,8.55071742 1.90676087,8.64469565 1.90676087,8.73873916 C1.90773913,11.0293043 1.90819565,13.3198695 1.90558696,15.6104348 C1.90545652,15.7273695 1.87702174,15.8442392 1.86176087,15.9611087 C1.86176087,15.9610435 1.86652174,15.9560217 1.86652174,15.9560217 Z" id="Path"></path>
      <path d="M1.86176087,15.9610435 C1.95684783,15.9457174 2.05193479,15.9171521 2.14702174,15.917087 C4.49197826,15.9147391 6.83686957,15.9152609 9.18182606,15.915913 C9.23556522,15.915913 9.28936957,15.9230217 9.34141304,15.9266739 C9.58721737,16.8922826 8.95591307,17.7603261 7.97791304,17.7691304 C6.4256087,17.7830869 4.87304348,17.7804783 3.32067391,17.7695217 C2.52404348,17.763913 1.96121739,17.2003696 1.90936957,16.400413 C1.89971739,16.2516522 1.88184782,16.103413 1.86717391,15.9555 C1.86652174,15.9560217 1.86176087,15.9610435 1.86176087,15.9610435 Z" id="Path"></path>
      <path d="M10.3284131,0.0161086956 C12.5310652,0.330847826 14.6763913,0.637434783 16.8473479,0.947673916 C16.8473479,1.25347826 16.8473479,1.54943478 16.8473479,1.87519565 C16.7386304,1.8813913 16.6339565,1.89241304 16.5292174,1.89247826 C14.8784348,1.89358695 13.2276522,1.88752174 11.577,1.89945652 C11.3310652,1.90121739 11.2106087,1.81617392 11.1112826,1.60167391 C10.8710869,1.083 10.6066957,0.575543479 10.3284131,0.0161086956 Z" id="Path"></path>
    </SvgIcon>
  );
}

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  //withStyles(styles),
)(CustomsIcon);




