// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  CUSTOM_CLAIMS_REQUEST,
  CUSTOM_CLAIMS_SUCCESS,
  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,
  FETCH_ITEMS,
  UPDATE_ITEMS,
  CUSTOM_CLAIMS_FAILURE,
  UPDATE_SCANS,
  ADD_ITEMS,
  RESET_ITEMS,
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Item
} from "../../../utils/types";
import type {
  Action
} from "redux";

export type itemsReducerType = {
  list: Item[],
  item: Item,
  pending: boolean,
  unsubscribe: any,
  serials: {},
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  item: null,
  pending: false,
  unsubscribe: null,
  serials: {},
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: itemsReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case GET_ITEM_REQUEST:
      newState.pending = true;
      return newState;
    case GET_ITEM_SUCCESS:
      newState.item = action.payload;
      newState.pending = false;
      return newState;
    case GET_ITEM_FAILURE:
      newState.pending = false;

    case CUSTOM_CLAIMS_REQUEST:
      newState.pending = true;
      return newState;

    case CUSTOM_CLAIMS_SUCCESS:
    case CUSTOM_CLAIMS_FAILURE:
      newState.pending = false;
      return newState;

    case FETCH_ITEMS:
      // Start spinning
      newState.pending = true;
      // Clear list
      newState.list = [];
      // Unsubscribe previous fetch if exist
      if (newState.unsubscribe) newState.unsubscribe();
      // Store new unSubscribe
      newState.unsubscribe = action.payload;
      return newState;

    case UPDATE_ITEMS:
      // Stop spinning
      newState.pending = false;
      // Store new list
      newState.list = action.payload ? action.payload : [];
      return newState;

    case RESET_ITEMS:
      // Clear list
      newState.list = [];
      return newState;

    case ADD_ITEMS:
      // Stop spinning
      newState.pending = false;
// Store new list
      newState.list = [...newState.list, ...action.payload];
      return newState;

    default:
      return newState;
  }
};
