// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

import { combineReducers } from 'redux';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import authReducer from './shared/modules/auth/redux/reducer';
import scansReducer from './shared/modules/scans/redux/reducer';
// import clustersReducer from './shared/modules/clusters/redux/reducer';
import lotsReducer from './shared/modules/lots/redux/reducer';
import itemsReducer from './shared/modules/items/redux/reducer';
import productsReducer from './shared/modules/products/redux/reducer';
import searchsReducer from './shared/modules/search/redux/reducer';
import suppliersReducer from './shared/modules/suppliers/redux/reducer';
import patientsReducer from "./shared/modules/patients/redux/reducer";
import employeesReducer from "./shared/modules/employees/redux/reducer";
import rewardsReducer from './shared/modules/rewards/redux/reducer';
import chartsReducer from './shared/modules/charts/redux/reducer';
import usersReducer from './shared/modules/users/redux/reducer';
import warehousesReducer from './shared/modules/warehouses/redux/reducer';
import pharmaciesReducer from './shared/modules/pharmacies/redux/reducer';
import notificationsReducer from './shared/modules/notifications/redux/reducer';
import type { scansReducerType } from "./shared/modules/scans/redux/reducer";
import type { clustersReducerType } from "./shared/modules/clusters/redux/reducer";
import type { authReducerType } from "./shared/modules/auth/redux/reducer";
import type { lotsReducerType } from "./shared/modules/lots/redux/reducer";
import type { itemsReducerType } from "./shared/modules/items/redux/reducer";
import type { productsReducerType } from "./shared/modules/products/redux/reducer";
import type { searchsReducerType } from "./shared/modules/search/redux/reducer";
import type { suppliersReducerType } from "./shared/modules/suppliers/redux/reducer";
import type { patientsReducerType } from "./shared/modules/patients/redux/reducer";
import type { employeesReducerType } from "./shared/modules/employees/redux/reducer";
import type { rewardsReducerType } from "./shared/modules/rewards/redux/reducer";
import type { usersReducerType } from "./shared/modules/users/redux/reducer";
import type { chartsReducerType } from "./shared/modules/charts/redux/reducer";
import type { warehousesReducerType } from "./shared/modules/warehouses/redux/reducer";
import type { pharmaciesReducerType } from "./shared/modules/pharmacies/redux/reducer";
import type { notificationsReducerType } from "./shared/modules/notifications/redux/reducer";

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

const appReducer = combineReducers({
  auth: authReducer,
  scans: scansReducer,
  // clusters: clustersReducer,
  lots: lotsReducer,
  items: itemsReducer,
  products: productsReducer,
  searchs: searchsReducer,
  suppliers: suppliersReducer,
  patients: patientsReducer,
  employees: employeesReducer,
  rewards: rewardsReducer,
  charts: chartsReducer,
  warehouses: warehousesReducer,
  users: usersReducer,
  pharmacies: pharmaciesReducer,
  notifications: notificationsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer;

export type RootState = {
  auth: authReducerType,
  scans: scansReducerType,
  // clusters: clustersReducerType,
  lots: lotsReducerType,
  items: itemsReducerType,
  products: productsReducerType,
  searchs: searchsReducerType,
  suppliers: suppliersReducerType,
  patients: patientsReducerType,
  employees: employeesReducerType,
  rewards: rewardsReducerType,
  charts: chartsReducerType,
  warehouses: warehousesReducerType,
  users: usersReducerType,
  pharmacies: pharmaciesReducerType,
  notifications: notificationsReducerType,
}
