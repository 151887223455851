// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Actions type
import {
  SEARCH_ALL_REQUEST,
  SEARCH_ALL_SUCCESS,
  SEARCH_ALL_FAILURE,
  SEARCH_PHARMACIES_REQUEST,
  SEARCH_PHARMACIES_SUCCESS,
  SEARCH_PHARMACIES_FAILURE, CLEAR_SEARCH
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Action
} from "redux";

export type searchesReducerType = {
  privateSearch: any[],
  publicSearch: any[],
  pharmacies: any[],
  query: string,
  pending: boolean,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  privateSearch: [],
  publicSearch: [],
  pharmacies: [],
  query: '',
  pending: false,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: searchesReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case SEARCH_ALL_REQUEST:
      newState.pending = true;
      return newState;

    case SEARCH_ALL_FAILURE:
      newState.publicSearch = [];
      newState.privateSearch = [];
      newState.pending = false;
      return newState;

    case SEARCH_ALL_SUCCESS:
      newState.publicSearch = action.payload  && action.payload.publicSearch ? action.payload.publicSearch : [];
      newState.privateSearch = action.payload  && action.payload.privateSearch ? action.payload.privateSearch : [];
      newState.pending = false;
      return newState;

    case SEARCH_PHARMACIES_FAILURE:
      newState.pharmacies = [];
      return newState;

    case SEARCH_PHARMACIES_SUCCESS:
      newState.query = action.payload ? action.payload.query : '';
      newState.pharmacies = action.payload ? action.payload.hits : [];
      return newState;

    default:
      return newState;
  }
};
