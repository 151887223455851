// @flow

// ---------------------------------------------------------------------------------------------- //
// Colors
// ---------------------------------------------------------------------------------------------- //

export const primary = '#1B578F';
export const secondary = '#3FB498';
export const dark = "#444444";
export const background = "#e5e5e5"
export const green = '#1CD68F';
export const red = 'indianred';
export const orange = 'darkorange';
export const grey = 'grey';
export const darkgrey = '#616161';
export const lightGrey = '#BBB';
export const white = 'white';
export const whiteOpacity025 = 'rgba(255, 255, 255, 0.25)';
export const text = '#424242';
export const color = (object) => {
  // Grab scan info
  const {legit, warning, fraud} = object;
  // Switch according to flags
  if (fraud) {
    return red;
  } else if (warning) {
    return orange;
  } else if (legit) {
    return green;
  } else {
    return grey;
  }
};
