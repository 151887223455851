// @flow

// ---------------------------------------------------------------------------------------------- //
// Lib
// ---------------------------------------------------------------------------------------------- //

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import { Action } from "redux";

// ---------------------------------------------------------------------------------------------- //
// Actions type
// ---------------------------------------------------------------------------------------------- //

import {
  POST_REQUEST_REQUEST,
  POST_REQUEST_SUCCESS,
  POST_REQUEST_FAILURE,
  GET_PHARMACY_REQUEST,
  GET_PHARMACY_SUCCESS,
  GET_PHARMACY_FAILURE,
  GET_GEO_PHARMACIES_REQUEST,
  GET_GEO_PHARMACIES_SUCCESS,
  GET_GEO_PHARMACIES_FAILURE,
  SET_SELECTED_PHARMACY_ID,
  SET_MAP_CAMERA,
  POST_FAVORITE_PHARMACY_REQUEST,
  DELETE_FAVORITE_PHARMACY_REQUEST,
  POST_FAVORITE_PHARMACY_SUCCESS,
  DELETE_FAVORITE_PHARMACY_SUCCESS,
  POST_FAVORITE_PHARMACY_FAILURE,
  DELETE_FAVORITE_PHARMACY_FAILURE,
  SET_PHARMACY_ID,
  SET_PHARMACY_NAME
} from "./action-types";

import {

} from "../../dialogs/redux/action-types";
import { PUT_USER_SUCCESS } from "../../users/redux/action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

export type pharmaciesReducerType = {
  list: any[],
  geolist: any[],
  ne: any,
  sw: any,
  pharmacy: any,
  pending: boolean,
  redirect: boolean,
  error: any,
  pharmacyId: string,
  name: string,
  favoritePending: boolean,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  list: [],
  geolist: [],
  ne: null,
  sw: null,
  pharmacy: null,
  pending: false,
  redirect: false,
  error: null,
  pharmacyId: null,
  name: null,
  favoritePending: false,
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

export default (
  state: pharmaciesReducerType = initialState,
  action: Action,
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case POST_REQUEST_REQUEST:
      newState.redirect = false;
      newState.pending = true;
      return newState;
    case POST_REQUEST_FAILURE:
      newState.redirect = false;
      newState.pending = false;
      return newState;
    case POST_REQUEST_SUCCESS:
      newState.redirect = true;
      newState.pending = false;
      return newState;
    case GET_PHARMACY_REQUEST:
      newState.pending = true;
      newState.pharmacy = null;
      return newState;
    case GET_PHARMACY_SUCCESS:
      newState.pending = false;
      newState.pharmacy = action.payload;
      return newState;
    case GET_GEO_PHARMACIES_REQUEST:
      newState.pending = true;
      return newState;
    case GET_GEO_PHARMACIES_SUCCESS:
      newState.pending = false;
      newState.geolist = action.payload;
      const geoPharmacyId = state.selectedPharmacyId
      const geoPharmacy = newState.geolist.find(item => item.id == geoPharmacyId)
      newState.selectedPharmacyId = geoPharmacy
        ? geoPharmacy.id
        : newState.geolist.length
          ? newState.geolist[0].id
          : null
      return newState;
    case GET_PHARMACY_FAILURE:
    case GET_GEO_PHARMACIES_FAILURE:
      newState.pending = false;
      newState.error = action.payload;
      return newState;
    case SET_SELECTED_PHARMACY_ID:
      const pharmacyId = action.payload
      const pharmacy = newState.geolist.find(item => item.id == pharmacyId)
      newState.selectedPharmacyId = pharmacy
        ? pharmacy.id
        : newState.geolist.length
          ? newState.geolist[0].id
          : null
      return newState
    case SET_MAP_CAMERA:
      newState.mapCamera = action.payload
      return newState
    case POST_FAVORITE_PHARMACY_REQUEST:
    case DELETE_FAVORITE_PHARMACY_REQUEST:
      newState.favoritePending = true;
      return newState;
    case POST_FAVORITE_PHARMACY_SUCCESS:
      // Grab pharmacyId
      const postPharmacyId = action.payload;
      // Stop spinning
      newState.favoritePending = false;
      // Set favorite pharmacy in list
      newState.geolist = newState.geolist.map(pharmacy => pharmacy.id === postPharmacyId
        ? { ...pharmacy, isFavorite: true }
        : pharmacy
      );
      // Set favorite pharmacy in individual
      if (newState.pharmacy && newState.pharmacy.id === postPharmacyId)
        newState.pharmacy.isFavorite = true;
      return newState;

    case DELETE_FAVORITE_PHARMACY_SUCCESS:
      // Grab pharmacyId
      const deletePharmacyId = action.payload;
      // Stop spinning
      newState.favoritePending = false;
      // Set favorite pharmacy in list
      newState.geolist = newState.geolist.map(pharmacy => pharmacy.id === deletePharmacyId
        ? { ...pharmacy, isFavorite: false }
        : pharmacy
      );
      // Set favorite pharmacy in individual
      if (newState.pharmacy && newState.pharmacy.id === deletePharmacyId)
        newState.pharmacy.isFavorite = false;
      return newState;

    case POST_FAVORITE_PHARMACY_FAILURE:
    case DELETE_FAVORITE_PHARMACY_FAILURE:
      newState.favoritePending = false;
      newState.error = action.payload;
      return newState;

    case SET_PHARMACY_ID:
      newState.pharmacyId = action.payload;
      return newState;

    case SET_PHARMACY_NAME:
      newState.name = action.payload;
      return newState;

    case PUT_USER_SUCCESS:
      newState.pharmacyId = null;
      newState.name = null;
      return newState;

    default:
      return newState;
  }
};
