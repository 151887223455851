// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import {
  PUT_USER_SUCCESS,
  PUT_USER_REQUEST,
  PUT_USER_FAILURE,
  SET_USER,
  CLEAR_USER,
  FETCH_USER,
  UPDATE_USER,
  FETCH_USER_FAILURE,
} from "./action-types";
import {
  LOGIN_SUCCESS,
  PATIENT_LOGIN_SUCCESS,
  PATIENT_SIGNUP_SUCCESS,
  SIGNUP_SUCCESS
} from "../../auth/redux/action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Action
} from "redux";
import type {User} from "../../../utils/types";


export type usersReducerType = {
  pending: boolean,
  error: any,
  fetched: boolean,
  user: User,
  unsubscribe: any,
  state: {
    lastName: string,
    firstName: string,
    birthdate: number,
    gender: string,
    pharmacyId: string,
  }
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  pending: false,
  error: null,
  fetched: false,
  updated: false,
  user: null,
  unsubscribe: null,
  state: {
    lastName: '',
    firstName: '',
    birthdate: null,
    gender: '',
    pharmacyId: null,
  }
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

const usersReducer = (
  state: usersReducerType = initialState,
  action: Action
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {

    case PUT_USER_REQUEST:
      newState.pending = true;
      return newState;

    case PUT_USER_SUCCESS:
      newState.pending = false;
      newState.updated = true;
      newState.user = action.payload;
      return newState;

    case FETCH_USER:
      newState.pending = true;
      newState.user = null;
      // Unsubscribe previous fetch if exist
      if (newState.unsubscribe) newState.unsubscribe();
      // Store new unSubscribe
      newState.unsubscribe = action.payload;
      return newState;

    case UPDATE_USER:
      newState.pending = false;
      newState.fetched = true;
      newState.updated = false;
      newState.user = action.payload;
      return newState;

    case FETCH_USER_FAILURE:
    case PUT_USER_FAILURE:
      newState.pending = false;
      newState.user = null;
      newState.error = action.payload;
      return newState;

    case FETCH_USER:
      newState.pending = true;
      newState.error = null;
      if (newState.unsubscribe) newState.unsubscribe();
      newState.unsubscribe = action.payload;
      return newState;

    case UPDATE_USER:
      newState.pending = false;
      newState.user = action.payload;
      return newState;

    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
    case PATIENT_SIGNUP_SUCCESS:
    case PATIENT_LOGIN_SUCCESS:
      newState.user = action.payload.user;
      return newState;

    case SET_USER:
      newState.state = action.payload;
      return newState;
    case CLEAR_USER:
      newState.state = initialState.state;
      return newState;

    default:
      return newState;
  }
};

export default usersReducer;
