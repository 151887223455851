// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import * as firebase from '../../../utils/firebase';
import * as api from '../../../utils/api';
import * as actions from "../../../../shared/modules/scans/redux/actions";

// ---------------------------------------------------------------------------------------------- //
// Functions
// ---------------------------------------------------------------------------------------------- //

export const GetScan = (
  scanId: string
): any => actions.GetScan(api, scanId);

export const FetchScans = (
  from?: Date,
  to?: Date,
  itemId?: string,
  lotId?: string,
  productId?: string,
  userId?: string,
  pharmacyId?: string,
  limit?: number,
  orderBy?: string,
  direction?: string,
  startAfter?: any,
): any => actions.FetchScans(firebase, from, to, itemId, lotId, productId, userId, pharmacyId, limit, orderBy, direction, startAfter);

export const PostScan = (
  barcode: string,
  type: string,
  device: string,
  lat: number,
  lng: number,
  accuracy: number,
  altitude: number,
  altitudeAccuracy: number,
  speed: number,
  heading: number,
) => actions.PostScan(api, barcode, type, device, lat, lng, accuracy, altitude, altitudeAccuracy, speed, heading);