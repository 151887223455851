// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {withRouter, Redirect} from 'react-router';

// Utils

import { isMobile } from '../shared-web/utils/getDeviceType';

// UI
import {
  AppBar,
  Toolbar,
  withStyles,
  makeStyles,
} from '@material-ui/core';


// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// UI

// Image
import logoWhite from '../shared/static/img/logo-brand-white.png';

// Actions

// Icon

// Routes
import * as Routes from '../shared/routes';

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  classes: {},
};

type State = {
};

// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  root: {
    height: '8vh',
  },
  logo: {
    height: '7vh',
  }
}));

const styles = theme => ({
});


  // -------------------------------------------------------------------------------------------- //
  // Component
  // -------------------------------------------------------------------------------------------- //


const NavBar = (props) =>{

// -------------------------------------------------------------------------------------------- //
// Render
// -------------------------------------------------------------------------------------------- //

// Render
  const { history } = props;
  const classes = useStyles()

  return (
      <div>
        <AppBar position="static" className={classes.root}>
          <Toolbar>
              <img
                src={logoWhite}
                className={classes.logo}
                onClick={() => {history.push({
                  pathname: Routes.home,
                })}}
                alt="logo"
              />
          </Toolbar>
        </AppBar>
      </div>
  );
};

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

const mapStateToProps = ({ auth,/* searchs,*/ users }) => {
  const { claims } = auth;
  // const { privateSearch, publicSearch } = searchs;
  const { user } = users;

  return {
    claims,
    // privateSearch,
    // publicSearch,
    user,
  };
};

const mapDispatchToProps = {
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter,
)(NavBar);
