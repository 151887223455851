// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
} from '@material-ui/core';
import { compose } from "recompose";

// Type

// UI

// Icons


// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// UI

// Actions


// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
};

type State = {
};

// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  root:{
    margin: theme.spacing(3),
  }
});

// ---------------------------------------------------------------------------------------------- //
// Components
// ---------------------------------------------------------------------------------------------- //

function TabPanel(props) {
  const { children, value, index, fullWidth, textColor, ...other } = props;

  console.log("props typo = ", props)
 
  // -------------------------------------------------------------------------------------------- //
  // Render
  // -------------------------------------------------------------------------------------------- //

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  withStyles(styles),
)(TabPanel);
