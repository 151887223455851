// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// UI
import {
  Snackbar,
  SnackbarContent,
  IconButton,
  withStyles,
} from '@material-ui/core';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Icons
import {
  ErrorIcon,
  CloseIcon
} from "../../../styles/icons";


// Actions
import {
  CloseFailure,
} from '../../../../shared/modules/auth/redux/actions';

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  classes: {},

};

type State = {
  email: string,
};

// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    marginLeft: '5px',
  },
});

// ---------------------------------------------------------------------------------------------- //
// Component
// ---------------------------------------------------------------------------------------------- //

class SnackbarError extends React.Component<Props, State> {
  // -------------------------------------------------------------------------------------------- //
  // Life cycle
  // -------------------------------------------------------------------------------------------- //

  constructor(props) {
    super(props);
    this.state = {
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.props.CloseFailure();
  };

  render() {
    const { classes, error } = this.props;

    let message = '';

//manque "Network Error"

    if (error) {
      switch (error.code) {
        case "auth/network-request-failed":
        message = "Problèmes de réseaux."
        break;
        case "auth/email-already-in-use":
        message = "Cette adresse mail est déjà utilisé par un autre compte."
        break;
        case "auth/invalid-email":
        message = "La synthaxe de l'adresse mail est incorrecte."
        break;
        case "auth/user-not-found":
        message = "Cette adresse mail ne correspond à aucun compte."
        break;
        case "auth/wrong-password":
        message = "Le mot de passe est invalide."
        break;
        default:
        message = error.message;
        break;
      }
    }

    return (
      <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={!!error}
      autoHideDuration={3000}
      onClose={this.handleClose}
      >
      <SnackbarContent
      className={classes.error}
      message={
        <div className={classes.errorContainer}>
        <ErrorIcon/>
        <p className={classes.errorMessage}>{message}</p>
        </div>
      }
      action={
        <IconButton
        key="close"
        color="inherit"
        className={classes.close}
        onClick={e => {
          e.preventDefault();
          this.handleClose();
        }}
        >
        <CloseIcon/>
        </IconButton>
      }
      />
      </Snackbar>
    )
  }
}

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

const mapStateToProps = ({ auth }) => {
  const { error } = auth;
  return {
    error,
  };
};

const mapDispatchToProps = {
  CloseFailure,
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SnackbarError);
