// @flow

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  IS_LOGGED_FAILURE,
  IS_LOGGED_REQUEST,
  IS_LOGGED_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SEND_PASSWORD_RESET_MAIL_REQUEST,
  SEND_PASSWORD_RESET_MAIL_SUCCESS,
  SEND_PASSWORD_RESET_MAIL_FAILURE,
  FAILURE_RESET,
  ANONYMOUS_LOGIN_REQUEST,
  ANONYMOUS_LOGIN_SUCCESS,
  ANONYMOUS_LOGIN_FAILURE,
  PHONE_VERIFICATION_REQUEST,
  PHONE_VERIFICATION_FAILURE,
  PHONE_VERIFICATION_SUCCESS,
  PATIENT_SIGNUP_REQUEST,
  PATIENT_LOGIN_REQUEST,
  PATIENT_SIGNUP_SUCCESS,
  PATIENT_LOGIN_SUCCESS,
  PATIENT_SIGNUP_FAILURE,
  PATIENT_LOGIN_FAILURE,
  CLEAR_SUCCESS,
  PHONE_VERIFICATION_CANCEL,
} from "./action-types";

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

import type {
  Action
} from "redux";

export type authReducerType = {
  isLogged: boolean,
  pending: boolean,
  fetched: boolean,
  error: any,
  success: boolean,
  claims: {
    pharmacy: boolean,
    pending: boolean,
    meditect: boolean,
    company: boolean,
    pharmacyId: string,
    companyId: string,
  },
  firebaseUser: any,
  deviceId: string,
  confirmResult: any,
  unsubscribe: any,
  firstName: string,
  lastName: string,
  phone: string,
  country: string,
}

// ---------------------------------------------------------------------------------------------- //
// Initial State
// ---------------------------------------------------------------------------------------------- //

const initialState = {
  isLogged: false,
  pending: false,
  fetched: false,
  success: false,
  error: null,
  userName: {},
  claims: {
    isPharmacy: false,
    isPending: false,
    isMeditect: false,
    isCompany: false,
    pharmacyId: null,
    companyId: null,
  },
  user: null,
  deviceId: null,
  confirmResult: null,
  unsubscribe: null,
  firstName: '',
  lastName: '',
  phone: null,
  country: '',
};

// ---------------------------------------------------------------------------------------------- //
// Reducer
// ---------------------------------------------------------------------------------------------- //

const authReducer = (
  state: authReducerType = initialState,
  action: Action
) => {
  const newState = Object.assign({}, state);
  switch (action.type) {

    case LOGIN_REQUEST:
    case IS_LOGGED_REQUEST:
    case SIGNUP_REQUEST:
    case ANONYMOUS_LOGIN_REQUEST:
    case PATIENT_SIGNUP_REQUEST:
    case PATIENT_LOGIN_REQUEST:
      // Start spinning
      newState.pending = true;
      newState.fetched = false;
      newState.isLogged = false;
      // Clean previous state
      newState.error = null;
      newState.claims = {};
      newState.deviceId = null;
      newState.firebaseUser = null;
      newState.confirmResult = null;
      return newState;

      case LOGIN_SUCCESS:
      case IS_LOGGED_SUCCESS:
      case SIGNUP_SUCCESS:
      case ANONYMOUS_LOGIN_SUCCESS:
      case PATIENT_SIGNUP_SUCCESS:
      case PATIENT_LOGIN_SUCCESS:
        newState.isLogged = !!action.payload.firebaseUser;
        newState.fetched = true;
        newState.pending = false;
        newState.error = null;
        newState.claims = action.payload.claims;
        newState.deviceId = action.payload.deviceId;
        newState.firebaseUser = action.payload.firebaseUser;
        newState.confirmResult = null;
        if (newState.unsubscribe) newState.unsubscribe();
        return newState;

    case LOGIN_FAILURE:
    case IS_LOGGED_FAILURE:
    case SIGNUP_FAILURE:
    case ANONYMOUS_LOGIN_FAILURE:
    case PATIENT_SIGNUP_FAILURE:
    case PATIENT_LOGIN_FAILURE:
      newState.pending = false;
      newState.error = action.payload;
      newState.confirmResult = null;
      if (newState.unsubscribe) newState.unsubscribe();
      return newState;

    case PHONE_VERIFICATION_REQUEST:
      newState.pending = true;
      newState.error = null;
      newState.firstName = action.payload.firstName;
      newState.lastName = action.payload.lastName;
      newState.country = action.payload.country;
      newState.phone = action.payload.phone;
      newState.unsubscribe = action.payload.unsubscribe;
      newState.send = action.payload.send;
      newState.confirmResult = null;
      return newState;

    case PHONE_VERIFICATION_SUCCESS:
      newState.pending = false;
      newState.confirmResult = action.payload;
      return newState;

    case PHONE_VERIFICATION_CANCEL:
      newState.pending = false;
      newState.firstName = '';
      newState.lastName = '';
      newState.country = '';
      newState.phone = null;
      newState.confirmResult = null;
      return newState;

    case PHONE_VERIFICATION_FAILURE:
      newState.pending = false;
      newState.error = action.payload;
      newState.firstName = '';
      newState.lastName = '';
      newState.country = '';
      newState.phone = null;
      return newState;

    case LOGOUT_REQUEST:
      newState.pending = true;
      newState.error = null;
      return newState;

    case LOGOUT_SUCCESS:
      newState.isLogged = false;
      newState.pending = false;
      newState.claims = {};
      newState.firebaseUser = null;
      newState.deviceId = null;
      newState.firstName = '';
      newState.lastName = '';
      newState.country = '';
      newState.phone = null;
      newState.confirmResult = null;
      return newState;

    case SEND_PASSWORD_RESET_MAIL_REQUEST:
      newState.pending = true;
      newState.error = null;
      newState.success = false;
      return newState;

    case SEND_PASSWORD_RESET_MAIL_SUCCESS:
      newState.pending = false;
      newState.success = true;
      return newState;

    case SEND_PASSWORD_RESET_MAIL_FAILURE:
    case LOGOUT_FAILURE:
      newState.pending = false;
      newState.error = action.payload;
      return newState;

    case FAILURE_RESET:
      newState.success = false;
      newState.error = null;
      return newState;

    case CLEAR_SUCCESS:
      newState.success = false;
      return newState;

    default:
      return newState;
  }
}

export default authReducer;
