// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';

// UI
import {
  CircularProgress,
} from '@material-ui/core';

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  spinning: boolean,
};

// ---------------------------------------------------------------------------------------------- //
// Function
// ---------------------------------------------------------------------------------------------- //

const Spinner = ({ spinning }): Props => {
  return (
    <div>
      {
        spinning &&
        <CircularProgress/>
      }
    </div>
  );
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default Spinner;
