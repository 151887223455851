// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// UI
import {
  Snackbar,
  SnackbarContent,
  IconButton,
  withStyles,
} from '@material-ui/core';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// Icons
import {
  DoneIcon,
  CloseIcon,
} from "../../../styles/icons";

// Actions
import {
  CloseFailure,
} from '../../../../shared/modules/auth/redux/actions';

// ---------------------------------------------------------------------------------------------- //
// Styles
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  error: {
    backgroundColor: 'green',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    marginLeft: '5px',
  },
});

// ---------------------------------------------------------------------------------------------- //
// Component
// ---------------------------------------------------------------------------------------------- //

class SnackbarSuccess extends React.Component<Props, State> {
  // -------------------------------------------------------------------------------------------- //
  // Life cycle
  // -------------------------------------------------------------------------------------------- //

  constructor(props) {
    super(props);
    this.state = {
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.props.CloseFailure();
  };

  render() {
    const { classes, success } = this.props;

    let message = "Le mail a bien été envoyé!";

    return (
      <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={!!success}
      autoHideDuration={3000}
      onClose={this.handleClose}
      >
      <SnackbarContent
      className={classes.error}
      message={
        <div className={classes.errorContainer}>
        <DoneIcon/>
        <p className={classes.errorMessage}>{message}</p>
        </div>
      }
      action={
        <IconButton
        key="close"
        color="inherit"
        className={classes.close}
        onClick={e => {
          e.preventDefault();
          this.handleClose();
        }}
        >
        <CloseIcon/>
        </IconButton>
      }
      />
      </Snackbar>
    )
  }
}

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

const mapStateToProps = ({ auth }) => {
  const { success } = auth;
  return {
    success,
  };
};

const mapDispatchToProps = {
  CloseFailure,
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SnackbarSuccess);
