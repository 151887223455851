// @flow

// ---------------------------------------------------------------------------------------------- //
// Libs
// ---------------------------------------------------------------------------------------------- //

// Core
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

// UI
import {
  FormControl,
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  CardHeader,
  withStyles
} from '@material-ui/core';

// Type
import { ThunkAction } from 'redux-thunk';

// ---------------------------------------------------------------------------------------------- //
// Imports
// ---------------------------------------------------------------------------------------------- //

// UI
import ButtonLink from '../../../../shared/ui/ButtonLink';
import Spinner from '../../../ui/Spinner';
import SnackbarError from '../ui/SnackbarError';
import SnackbarSuccess from '../ui/SnackbarSuccess';

// Icons
import {
  LogInIcon,
  SignUpIcon
} from "../../../styles/icons";

// Utils
import {
  PASSWORD_REGEX,
} from '../../../../shared/utils/constants';

import {
  LogIn,
  SendPasswordResetEmail,
} from "../redux/actions";

// Routes
import * as Routes from '../../../../shared/routes';

// ---------------------------------------------------------------------------------------------- //
// Type
// ---------------------------------------------------------------------------------------------- //

type Props = {
  classes: {},
  LogIn: ThunkAction,
};

type State = {
  email: string,
  password: string,
};

// ---------------------------------------------------------------------------------------------- //
// Style
// ---------------------------------------------------------------------------------------------- //

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:  'center',
  },
  card: {
    padding: theme.spacing(1) ,
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardContent: {
    paddingTop: 0,
  },
  cardActions: {
    width: 600,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
});

// ---------------------------------------------------------------------------------------------- //
// Component
// ---------------------------------------------------------------------------------------------- //

class Login extends React.Component<Props, State> {
  // -------------------------------------------------------------------------------------------- //
  // Life cycle
  // -------------------------------------------------------------------------------------------- //

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      email: '',
    }
  };

  // -------------------------------------------------------------------------------------------- //
  // Functions
  // -------------------------------------------------------------------------------------------- //

  onLogin() {
    // Grab state
    const { email, password } = this.state;
    // Call action
    this.props.LogIn(email, password);
  }

  onChange(event) {
    this.setState({[event.target.id]: event.target.value})
  }

  checkPassword() {
    return !PASSWORD_REGEX.test(this.state.password);
  }


  sendPasswordResetEmail() {
    this.props.SendPasswordResetEmail(email);
  }

  // -------------------------------------------------------------------------------------------- //
  // Render
  // -------------------------------------------------------------------------------------------- //

  render() {
    // Grab props and states
    const { email } = this.state;
    const { classes, pending } = this.props;

    // Return login otherwise
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title='Connexion'
          />
          <CardContent className={classes.cardContent}>
            <FormControl margin='normal' fullWidth>
              <TextField
                id="email"
                label="Email"
                className={classes.textField}
                type="email"
                margin="normal"
                onChange={(event) => this.onChange(event)}
              />
              <TextField
                id="password"
                label="Mot de passe"
                className={classes.textField}
                type="password"
                autoComplete="current-password"
                margin="normal"
                onChange={(event) => this.onChange(event)}
                onKeyPress={ (e) => {
                  if (e.key === 'Enter') {
                    this.onLogin();
                  }
                }}
              />
            </FormControl>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                variant='contained'
                color="primary"
                className={classes.button}
                disabled={ this.checkPassword() || !email || pending }
                onClick={() => this.onLogin()}
              >
                <LogInIcon className={classes.leftIcon}/>
                Se connecter
              </Button>
              <Button
                variant='contained'
                className={classes.button}
                onClick={() => this.props.history.push(Routes.signup)}
              >
                <SignUpIcon className={classes.leftIcon}/>
                S'inscrire
              </Button>

              <Link to="/reset" >
                <ButtonLink text="Mot de passe oublié" />
              </Link>
            </CardActions>
          <Spinner
            spinning={pending}
          />
        </Card>
        <SnackbarError/>
        <SnackbarSuccess/>
      </div>
    )
  }
}

// ---------------------------------------------------------------------------------------------- //
// Redux
// ---------------------------------------------------------------------------------------------- //

const mapStateToProps = ({ auth }) => {
  const { isLogged, pending } = auth;
  return {
    isLogged,
    pending,
  };
};

const mapDispatchToProps = {
  LogIn,
  SendPasswordResetEmail,
};

// ---------------------------------------------------------------------------------------------- //
// Export
// ---------------------------------------------------------------------------------------------- //

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Login);
